import React from "react";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";



const AddTag = () => {
    const options = [
        { value: 'home', label: 'Home' },
        { value: 'new-in', label: 'New In' },
        { value: 'designers', label: 'Designers' },
        { value: 'women-category', label: 'Women Category' },
        { value: 'men-category', label: 'Men Category' },
        { value: 'brand', label: 'Brand' },
        { value: 'collaboration', label: 'Brand Collaboration' },
        { value: 'rakhi-curation', label: 'Rakhi Curation' },
    ]

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add SEO</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">

                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">SEO Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Page List
                            </label>
                            <div>
                                <Select
                                    options={options}
                                    classNamePrefix="select"
                                    //defaultValue={userTypes[0]}
                                    placeholder="Select"
                                    //value={selectedUserType ? selectedUserType : userTypes[0]}
                                    // // onBlur={handleBlur}
                                    name="userStatus"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Title
                            </label>
                            <div className="">
                                <input
                                    value=""
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name=""
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Title"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta keywords
                            </label>
                            <div className="">
                                <input
                                    value=""
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name=""
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta keywords"
                                />

                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Description
                            </label>
                            <div className="">
                                <textarea
                                    value=""
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name=""
                                    className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Description"
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddTag