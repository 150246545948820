import Api from "./Api";
import { toast } from "react-toast";

export default {
  // USER
  async getAllUsers() {
    try {
      const response = await Api().post("/api/admin/get-users");

      return response;
    } catch (error) {
      console.log("error in getAllUsers",error)
      return error
    }
  },

  async getUserById(id) {
    try {
      const response = await Api().get(`/api/admin/get-users/${id}`);

      return response;
    } catch (error) {
      console.log("error in getUserById",error)
      return error
    }
  },

  async addUsers(payload){
    try {
      const response = await Api().post("/api/register-user", payload);

      return response;
    } catch (error) {
      console.log("error in addUsers",error)
      return error
    }
  },

  async editUser(payload){
    try {
      const response = await Api().post("/api/update-profile-dashboard", payload);

      return response;
    } catch (error) {
      console.log("error in addUsers",error)
      return error
    }
  },

  // Dashboard
  async getAllDashboard(data) {
    try {
      var url = "/api/admin/get-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      console.log("error in getAllDashboard",error)
      return error
    }
  },

  // Dashboard Line Graph API
  async getAllDashboardLineGarph(data) {
    try {
      var url = "/api/admin/get-daywise-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      console.log("error in getAllDashboardLineGarph",error)
      return error
    }
  },

  // PRODUCT
  async addProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/products", body);

      return response;
    } catch (error) {
      console.log("error in addProducts",error)
      return error
    }
  },
  async editProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/products", body);

      return response;
    } catch (error) {
      console.log("error in editProducts",error)
      return error
    }
  },

  async getProductImage(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/fileupload/uploadFile", body);

      return response;
    } catch (error) {
      console.log("error in getProductImage",error)
      return error
    }
  },
  async getAllProduct() {
    try {
      const response = await Api().get("/api/admin/products");

      return response;
    } catch (error) {
      console.log("error in getAllProduct",error)
      return error
    }
  },
  async getAllPageMeta() {
    try {
      const response = await Api().get("/api/admin/seo");

      return response;
    } catch (error) {
      console.log("error in getAllPageMeta",error)
      return error
    }
  },
  async getDesginerProductCount(id) {
    try {
      const response = await Api().get(
        `/api/admin/get-designer-product-count/${id}`
      );

      return response;
    } catch (error) {
      console.log("error in getDesginerProductCount",error)
      return error
    }
  },

  async getProductById(id) {
    try {
      const response = await Api().get(`/api/admin/products/?product_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getProductById",error)
      return error
    }
  },

  // ORDERS
  async getAllOrder() {
    try {
      const response = await Api().get("/api/admin/get-orders");

      return response;
    } catch (error) {
      console.log("error in getAllOrder",error)
      return error
    }
  },

  async addOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/create-order", body);

      return response;
    } catch (error) {
      console.log("error in addOrders",error)
      return error
    }
  },
  async editOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/orders", body);

      return response;
    } catch (error) {
      console.log("error in editOrders",error)
      return error
    }
  },
  async getOrderById(id) {
    try {
      const response = await Api().get(`/api/admin/get-orders/?order_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getOrderById",error)
      return error
    }
  },

  async getOrderStatus() {
    try {
      const response = await Api().get("/api/admin/get-order-status");

      return response;
    } catch (error) {
      console.log("error in getOrderStatus",error)
      return error
    }
  },

  async getAddressById(id) {
    try {
      const response = await Api().get(`https://api-prod.revivify.com/api/admin/address?user_id=${id}`);

      return response;
    } catch (error) {
      console.log("error in getAddressById",error)
      return error
    }
  },




  // CATEGORY

  async addCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/product-category", body);

      return response;
    } catch (error) {
      console.log("error in addCategory",error)
      return error
    }
  },
  async editCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/product-category", body);

      return response;
    } catch (error) {
      console.log("error in editCategory",error)
      return error
    }
  },
  async getAllCategory() {
    try {
      const response = await Api().get("/api/admin/product-category");

      return response;
    } catch (error) {
      console.log("error in getAllCategory",error)
      return error
    }
  },
  async getSingleCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-category/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleCategory",error)
      return error
    }
  },
  async getSingleSubCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-sub-category/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleSubCategory",error)
      return error
    }
  },

  // ATTRIBUTE

  async getAllAttributes() {
    try {
      const response = await Api().get("/api/admin/product-attributes");
      return response;
    } catch (error) {
      console.log("error in getAllAttributes",error)
      return error
    }
  },

  async addAttributesValue(payload) {
    try {
      const response = await Api().post("/api/admin/attribute-values", payload);

      return response;
    } catch (error) {
      console.log("error in addAttributesValue",error)
      return error
    }
  },

  async getAllAttributesColorValue(id) {
    try {
      const response = await Api().get(
        `api/admin/attribute-values/?p_product_attribute_id=${id}`
      );
      return response;
    } catch (error) {
      console.log("error in getAllAttributesColorValue",error)
      return error
    }
  },

  async addProductAttribute(data) {
    try {
      const response = await Api().post(`/api/admin/product-attributes`, data);
      return response;
    } catch (error) {
      console.log("error in addProductAttribute",error)
      return error
    }
  },

  async addAttributeChild(data) {
    try {
      const response = await Api().post(`/api/admin/attribute-child`, data);
      return response;
    } catch (error) {
      console.log("error in addAttributeChild",error)
      return error
    }
  },

  async getAttributes() {
    try {
      const response = await Api().get(`/api/admin/product-attributes`);
      return response;
    } catch (error) {
      console.log("error in getAttributes",error)
      return error
    }
  },
  async getAttributeChild() {
    try {
      const response = await Api().get(`/api/admin/attribute-child`);
      return response;
    } catch (error) {
      console.log("error in getAttributeChild",error)
      return error
    }
  },

  async getChildAttriValue(id){
    try {
      const response = await Api().get(`api/admin/attribute-values/?p_child_attribute_id=${id}`);
      return response;
    } catch (error) {
      console.log("error in getChildAttriValue",error)
      return error
    }
  }
,

  // BRANDS
  async getAllBrands() {
    try {
      const response = await Api().get("/api/admin/designers");
      return response;
    } catch (error) {
      console.log("error in getAllBrands",error)
      return error
    }
  },

  async addBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/designers", body);

      return response;
    } catch (error) {
      console.log("error in addBrand",error)
      return error
    }
  },

  async editBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/designers", body);

      return response;
    } catch (error) {
      console.log("error in editBrand",error)
      return error
    }
  },

  async getSingleBrand(id) {
    try {
      const response = await Api().get(`/api/admin/designers/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleBrand",error)
      return error
    }
  },
  async getSingleSubBrand(id) {
    try {
      const response = await Api().get(`/api/admin/sub-designers/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleSubBrand",error)
      return error
    }
  },

  async checkSkuValue(value){
    try {
      const response = await Api().get(`/api/product/sku-check/${value}`);

      return response;
    } catch (error) {
      console.log("error in checkSkuValue",error)
      return error
    }
  },

  // Abandoned Cart
  async getAbandonedList() {
    try {
      const response = await Api().get(`/api/admin/get-abandant-cart`);

      return response;
    } catch (error) {
      console.log("error in getAbandonedList",error)
      return error
    }
  },

  // Voucher
  async addVoucher(payload) {
    try {
      const response = await Api().post(`/api/voucher/add`,payload);

      return response;
    } catch (error) {
      console.log("error in addVoucher",error)
      return error
    }
  },

  async editVoucher(payload) {
    try {
      const response = await Api().put(`/api/voucher/edit`,payload);

      return response;
    } catch (error) {
      console.log("error in editVoucher",error)
      return error
    }
  },



  async getAllVoucher() {
    try {
      const response = await Api().get(`/api/voucher/get`);

      return response;
    } catch (error) {
      console.log("error in getAbandonedList",error)
      return error
    }
  },
  async getSingleVoucher(id) {
    try {
      const response = await Api().get(`/api/voucher/getSingle/${id}`);

      return response;
    } catch (error) {
      console.log("error in getSingleVoucher",error)
      return error
    }
  },

  async applyCoupon(payload) {
    try {
      const response = await Api().post(`api/voucher/apply`,payload);

      return response;
    } catch (error) {
      console.log("error in addVoucher",error)
      return error
    }
  },

};
