import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import { IMAGEURL } from "../../../helper/constant";
import UploadFileComp from "../../../components/UploadFileComp";
import MasterServices from "../../../ApiServices/MasterServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";

const AddBrand = () => {
    const [singleImage, setSingleImage] = useState(null);
    const [selectParentBrand, setSelectParentBrand] = useState(null);
    const [singleImageProgress, setSingleImageProgress] = useState(0);
    const [parentBrandOption, setParentBrandOption] = useState([]);
    const [editBrand, setEditBrand] = useState({});
    const [brandId, setBrandId] = useState(null);
    const [subBrandId, setSubBrandId] = useState(null)
    const [typeOfBrand, setTypeOfBrand] = useState(null);
    const [parentID, setParentId] = useState(null);

    const { brand_id } = useParams();
    const locationUrl = useLocation();
    const formValues = {
        m_name: "",
        p_brand_desc: "",
        m_designer_active: "Y",


        m_meta_title: "",
        m_meta_tags: "",
        m_meta_dasc: "",
        m_route_value: "",

        m_item_id: null,
        m_route_id: null,
        m_route_status: "",


    }
    const [initialValues, setInitialValues] = useState(formValues);
    const navigate = useNavigate()
    const brandOption = [
        {
            label: "abnc",
            value: 1,
        },
        {
            label: "dsssf",
            value: 2,
        },
    ]
    useEffect(() => {
        const fetchData = () => {
            getParentBrand()
        }
        fetchData()
    }, [])

    const getBrandById = async (id) => {
        try {

            const response = await MasterServices.getSingleBrand(id);
            console.log("getBrandById", response)
            if (response?.data?.data) {
                setEditBrand(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }
    }

    const getSubBrandId = async (id) => {
        try {

            const response = await MasterServices.getSingleSubBrand(id);
            console.log("getSubBrandId", response?.data?.data)
            if (response?.data?.data) {
                setEditBrand(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }
    }

    const getParentBrand = async () => {
        try {

            const response = await MasterServices.getAllBrands();
            console.log("getParentBrand", response)
            if (response?.data?.data?.length > 0) {

                const filter = response?.data?.data.map((ele) => ({
                    label: ele?.m_name,
                    value: ele?.m_designers_id
                }))
                // setParentBrandOption(filter)
                const optionsWithNone = [{ value: null, label: "None" }, ...filter];
                setParentBrandOption(optionsWithNone)

            }
        } catch (error) {
            toast.error("error in getParentCategory")
        }
    }

    useEffect(() => {
        const fetchData = async () => {

            if (locationUrl.pathname.includes("editBrand")) {
                await getBrandById(brand_id)
            }
            else {
                await getSubBrandId(brand_id)
            }
        }
        fetchData()
    }, [brand_id])

    useEffect(() => {
        if (editBrand?.length > 0) {
            // if(editBrand?.subdesigner?.length>0 && editBrand?.subdesigner[0]?.m_sub_designers_id){
            //     setInitialValues({
            //         m_name: editBrand?.subdesigner?.length>0 ? editBrand?.subdesigner[0]?.m_name :"",
            //         p_brand_desc: "",
            //         m_designer_active: "Y",

            //         m_meta_title: editBrand?.m_meta_title,
            //         m_meta_tags: editBrand?.m_meta_tags,
            //         m_meta_dasc: editBrand?.m_meta_dasc,
            //         m_route_value: editBrand?.m_route_value,

            //         m_item_id: editBrand?.m_item_id,
            //         m_route_id: editBrand?.m_route_id,
            //         m_route_status: editBrand?.m_route_status_active,
            //     })
            //     setSingleImage(editBrand?.subdesigner?.length>0 ? editBrand?.subdesigner[0]?.m_sub_designer_image1 :null);
            //     setSelectParentBrand({
            //         label:editBrand?.m_name,
            //         value:editBrand?.m_designers_id
            //     })
            //     setSubBrandId(editBrand?.subdesigner?.length>0 ? editBrand?.subdesigner[0]?.m_sub_designers_id :null)
            //     setParentId(editBrand?.m_designers_id);
            //     setTypeOfCategory("ChildCategory")
            // }else{
            //     setInitialValues({
            //         m_name: editBrand?.m_name,
            //         p_brand_desc: "",
            //         m_designer_active: "Y",

            //         m_meta_title: editBrand?.m_meta_title,
            //         m_meta_tags: editBrand?.m_meta_tags,
            //         m_meta_dasc: editBrand?.m_meta_dasc,
            //         m_route_value: editBrand?.m_route_value,

            //         m_item_id: editBrand?.m_item_id,
            //         m_route_id: editBrand?.m_route_id,
            //         m_route_status: editBrand?.m_route_status_active,
            //     })

            //     setSingleImage(editBrand?.m_designer_image1)

            //     setBrandId(editBrand?.m_designers_id)
            //     setParentId(null);
            //     setTypeOfCategory("ParentBrand")
            // }

            editBrand?.forEach((item) => {
                if (item?.m_sub_designers_id) {
                    setInitialValues({
                        m_name: item?.m_name,
                        p_brand_desc: "",
                        m_designer_active: item?.m_sub_designer_active,

                        m_meta_title: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_title : "",
                        m_meta_tags: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_tags : "",
                        m_meta_dasc: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_dasc : "",
                        m_route_value: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_value : "",

                        m_item_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_item_id : "",
                        m_route_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_id : "",
                        m_route_status: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_status_active : "",
                    })
                    setSingleImage(item?.m_sub_designer_image1)
                    setParentId(item?.m_designers_id);
                    setTypeOfBrand("ChildBrand");
                    setSubBrandId(item?.m_sub_designers_id)
                    if (item?.m_designers_id) {
                        console.log("parentBrandOption", parentBrandOption)
                        parentBrandOption?.length > 0 && parentBrandOption.forEach((ele) => {
                            if (ele?.value === item?.m_designers_id) {
                                setSelectParentBrand(({
                                    label: ele?.label,
                                    value: ele?.value
                                }))
                            }

                        })
                    }

                }
            })
        } else {
            setInitialValues({
                m_name: editBrand?.m_name,
                p_brand_desc: "",
                m_designer_active: editBrand?.m_designer_active,

                m_meta_title: editBrand?.m_meta_title,
                m_meta_tags: editBrand?.m_meta_tags,
                m_meta_dasc: editBrand?.m_meta_dasc,
                m_route_value: editBrand?.m_route_value,

                m_item_id: editBrand?.m_item_id,
                m_route_id: editBrand?.m_route_id,
                m_route_status: editBrand?.m_route_status_active,
            })

            setSingleImage(editBrand?.m_designer_image1)

            setBrandId(editBrand?.m_designers_id)
            setParentId(null);
            setTypeOfBrand("ParentBrand")
        }


    }, [editBrand, parentBrandOption])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {
            const body = {
                ...values,
                p_parent_brand_id: selectParentBrand?.value ? selectParentBrand?.value : null,
                p_brand_image_1: singleImage ? singleImage : null,
                m_sub_designers_id: subBrandId ? subBrandId : null,
                parentID: editBrand?.length > 0 ? parentID : null,
                typeOfBrand: editBrand?.length > 0 ? typeOfBrand : typeOfBrand
            }

            console.log("body", body)
            if (brand_id) {
                editBrandButton(body)
            } else {
                addBrandButton(body)
            }

        }
    })

    const editBrandButton = async (body) => {
        console.log("editBrandButton", body)
        try {
            const response = await MasterServices.editBrand(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("brand edited Successfully");
                navigate("/brand-page")
                clearForm();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("An error occurred while editig the brand");
        }
    }
    const addBrandButton = async (body) => {
        console.log("addBrandButton", body);
        try {
            const response = await MasterServices.addBrand(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("brand Added Successfully");
                navigate("/brand-page")
                clearForm();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("An error occurred while adding the brand");
        }


    }

    const clearForm = () => {
        navigate("/brand-page")

    }

    const handleFileChange = async (e, setImageState) => {
        const file = e.target.files[0];
        if (file) {

            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        e.target.value = ''; // Clear the file input
    }


    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{brand_id ? "Edit Brand" : "Add Brand"}</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 " onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Brand Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="">
                                <input
                                    value={values.m_name}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Name"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Slug
                            </label>
                            <div className="">
                                <input
                                    value={values.m_route_value}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_route_value"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Slug"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Parent Brand
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    //defaultValue={userTypes[0]}
                                    placeholder="Select"
                                    value={selectParentBrand}
                                    onChange={(selectedOption) => {
                                        setSelectParentBrand(selectedOption)
                                    }}
                                    options={parentBrandOption}
                                    name="p_parent_brand_id"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.p_brand_desc}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="p_brand_desc"
                                    className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Description"
                                />

                            </div>
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Thumbnail
                            </label>
                            <div className=" ">
                                {
                                    singleImage ?
                                        <div className="flex items-center justify-center w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200">
                                            <div className="relative">
                                                <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                    <img src={`${IMAGEURL}${singleImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                            onClick={() => setSingleImage(null)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="flex items-center justify-center w-full">
                                            {
                                                parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                    <div className="w-full h-60 flex justify-center items-center">
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                        <span className="text-md text-gray-500">* Upload Image</span>
                                                        <input type="file" class="hidden" accept="image/*" onChange={(e) => handleFileChange(e, setSingleImage)} />

                                                    </label>
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Meta Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Title
                            </label>
                            <div className="">
                                <input
                                    value={values.m_meta_title}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_title"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Title"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta keywords
                            </label>
                            <div className="">
                                <input
                                    value={values.m_meta_tags}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_tags"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta keywords"
                                />

                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.m_meta_dasc}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_dasc"
                                    className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Description"
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddBrand