import React from "react";
import { Oval } from "react-loader-spinner";
const Spinner = () => {
    return (
        <>
        <div className="flex justify-center items-center">

            <Oval
                visible={true}
                height="50"
                width="50"
                color="#a96a37"
                secondaryColor="#ebac78"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
        </>

    )
}

export default Spinner;