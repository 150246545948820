import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { customStyles } from "../../../helper/customStyles";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const navigation = [
    { name: 'General', to: '#', id: 4, current: false },
    { name: 'Inventory', to: '#', id: 1, current: false },
    { name: 'Attributes', to: '#', id: 2, current: true },
    { name: 'Variation', to: '#', id: 3, current: false },


]
const Attributes = (
    {
        attributes,
        setSelectProductOption,
        selectProductOption,
        ProductSelectOption,
        values,
        handleChange,
        setColorValue,
        colorValue,
        setVariationCheck,
        variationCheck,
        setAttributeData,
        attributeData,
        error,
        setError,
        selectAttribute,
        setSelectAttribute,
        attributesOption,
        variationData,
        setVariationData,
        setParentAttribute,
        parentAttribute,
        selectChildValue,
        setSelectChildValue,
        childInputValue,
        setChildInputValue,
        loopVariation,
        setLoopVariation,
        setVariationForm,
        variationForm,
        initialFormState,
        setLoading,
        loading,
        product_id,
        editProduct,
        flagParentAttribute,
        viewFlag

    }) => {

    const [navId, setNavId] = useState(4)
    const [navigationFilter, setNavigationFilter] = useState([])
    const [colorValueOption, setColorValueOption] = useState({});
    
   
    const navHandler = (id) => {
        setNavId(id)
    }


    useEffect(() => {
        let filteredNavigation = navigation.filter(ele => {
            if (selectProductOption?.value === 1 && ele.id === 3) {
                return false;
            }
            return true;
        });

        console.log("filteredNavigation", filteredNavigation)
        setNavigationFilter(filteredNavigation)
    }, [selectProductOption])

   
    useEffect(() => {
        const updatedVariationData = parentAttribute?.length > 0 && parentAttribute?.map((ele, index) => {
            let p_product_attribute_id = ele.p_product_attribute_id;
            console.log("element",ele)
            // if(variationCheck.find(pp=>pp.parentId === ele?.p_product_attribute_id)){
                return {
                    data: selectChildValue?.keystatic?.find(item => item.p_product_attribute_id === p_product_attribute_id)?.data || [],
                    p_attribute_key_id: p_product_attribute_id,
                    // variationCheck: variationCheck[index]?.checked ? true : false,
                    variationCheck:variationCheck.find(pp=>pp.parentId ===ele?.p_product_attribute_id)?true:false
    
                };
            // }
            // return undefined;
        }).filter(item => item !== undefined);

        console.log("updatedVariationData",{ updatedVariationData,variationCheck})
        setAttributeData(updatedVariationData);
        setLoopVariation(updatedVariationData)
    }, [parentAttribute, selectChildValue, variationCheck,variationData]);


    const handleSelectAttributeChange = async (selectedValues) => {
        console.log("selectedValues", selectedValues)

        if (selectedValues?.length > 0) {
            

            const updatedSelectedValues = await Promise.all(selectedValues.map(async (ele) => {
                if (attributesOption.some(dd => dd?.p_product_attribute_id === ele?.p_product_attribute_id && ele?.p_child_attributes?.length === 0)) {
                    setLoading(true)
                    const response = await MasterServices.getAllAttributesColorValue(ele?.p_product_attribute_id);
                    console.log("response", response);
                    if (response?.data?.data?.length > 0) {
                        setLoading(false)
                        const filterData = response?.data?.data.map((dd) => ({
                            ...dd,
                            value: dd.p_attribute_value_id,
                            label: dd?.p_attribute_value_name,
                        }));
                        console.log("filterData", filterData);
                        ele.childAttriOption = filterData;
                    }
                } else {
                    if (ele?.p_child_attributes?.length > 0) {
                        const filter = ele?.p_child_attributes.map((childEle) => ({
                            ...childEle,
                            label: childEle?.p_child_attribute_name,
                            value: childEle?.p_child_attribute_id,
                        }));
                        ele.childAttriOption = filter;
                        console.log("filter", filter);
                    }
                }
                return ele;
            }));

            setParentAttribute(updatedSelectedValues);

            const selectedAttributeNames = updatedSelectedValues.map(ele => ele?.p_product_attribute_id);
            setSelectChildValue(prevState => {
                const newState = { ...prevState };
                if (newState.keystatic) {
                    newState.keystatic = newState.keystatic.filter(item => selectedAttributeNames.includes(item.p_product_attribute_id));
                }
                return newState;
            });

        } else {
            setParentAttribute([]);
            setSelectChildValue(prevState => {
                const newState = { ...prevState };
                newState.keystatic = [];
                return newState;
            });
            setVariationCheck([])
        }
    };



    const handleChildAttribute = async (selectedValues, parentId, parentIndex) => {

        setSelectChildValue(prevState => {
            const newKeystatic = prevState?.keystatic ? prevState?.keystatic?.filter(item => item.p_product_attribute_id !== parentId) : [];
            console.log("newKeystatic", newKeystatic)
            return {
                ...prevState,
                keystatic: [
                    ...newKeystatic,
                    {
                        p_product_attribute_id: parentId,
                        data: selectedValues,
                        parentIndex: parentIndex
                    }
                ],
            };
        });

    }


    const handleInputChange = (parentId, childId, inputValue) => {
        setSelectChildValue(prevState => {
            const updatedKeystatic = prevState.keystatic.map(item => {
                if (item?.p_product_attribute_id === parentId) {
                    const updatedData = item.data.map(dd => {
                        if (dd.p_child_attribute_id === childId) {
                            // setChildInputValue(inputValue)
                            return {
                                ...dd,
                                inputValue: inputValue
                            };
                        }
                        return dd;
                    });
                    return {
                        ...item,
                        data: updatedData
                    };
                }
                return item;
            });
            return {
                ...prevState,
                keystatic: updatedKeystatic
            };
        });

        const updatedInput = {
            childId: childId,
            inputValue: inputValue
        };

        // Update state while preserving previous values
        setChildInputValue((prevValues) => {
            // Check if the childId already exists in state
            const existingIndex = prevValues.findIndex(item => item.childId === childId);
            console.log("existingIndex", existingIndex)
            if (existingIndex !== -1) {
                // If childId exists, update the inputValue
                const updatedValues = [...prevValues];
                updatedValues[existingIndex].inputValue = inputValue;
                return updatedValues;
            } else {
                // If childId does not exist, add new entry
                return [...prevValues, updatedInput];
            }
        });


    };

    const handleVariationCheck = (checked, ind, parentId) => {
       
        setVariationCheck(prevState => {
            const updatedState = [...prevState];
            const existingIndex = updatedState.findIndex(item => item.parentId === parentId);
            console.log("existingIndex",existingIndex)
            if (existingIndex !== -1) {
                updatedState[existingIndex].checked = checked;
            } else {
                updatedState.push({ checked, parentId });
            }
            console.log("updatedState",updatedState)
            return updatedState;
        });


    }

    const handleVariationForm = (childAttributeId, field, value, dd) => {
        console.log("variationForm", variationForm)

        if (childAttributeId === undefined || childAttributeId === null) {
            console.log("Invalid childAttributeId, no update made.");
            return;
        }

        const updatedForm = variationForm.map((item) =>
            item.childAttributeId === childAttributeId
                ? {
                    ...item,
                    [field]: value,
                    p_product_attribute_name: dd?.p_product_attribute_name,
                    p_product_attribute_id: dd?.p_product_attribute_id,
                    childAttributeName: dd?.p_attribute_value_name,
                    childAttributeId: dd?.p_attribute_value_id
                }
                : item
        );
        console.log("updatedForm", updatedForm)
        setVariationForm(updatedForm);
        setVariationData(updatedForm)
    };

    useEffect(() => {

        if (Array.isArray(loopVariation)) {
            const updatedForm = loopVariation
                .filter(item =>
                    Array.isArray(item.data) && 
                    variationCheck.some(pp => pp?.parentId === item?.p_attribute_key_id && item?.variationCheck === pp?.checked)
                )
                .flatMap(item =>
                    item.data.map(dd => ({
                        price: 0,
                        stock: 0,
                        description: '',
                   
                        childAttributeId: dd.p_attribute_value_id
                    }))
                );

            setVariationForm(prevForm => {
                const newForm = updatedForm
                    .filter(item => item.childAttributeId !== null && item.childAttributeId !== undefined)
                    .map(item => ({
                        ...item,
                        ...(prevForm?.find(formItem => formItem.childAttributeId === item.childAttributeId) || {}),
                    }));
                return newForm;
            });
        } else {
            console.error('loopVariation is not an array:', loopVariation);
            
        }
    }, [loopVariation, variationCheck, selectChildValue]);


    // console.log("variationForm",{
    //     variationForm,
    //     variationCheck,
    //     selectChildValue,
    //     loopVariation
    // })

    useEffect(()=>{
        if(editProduct?.length>0 && flagParentAttribute){
            console.log("helllo",parentAttribute)
            const fetchData = async()=>{
                if(parentAttribute?.length>0){
                    console.log("parentAttribute",parentAttribute)
                    await handleSelectAttributeChange(parentAttribute)
                }
                
            }
            fetchData()
        }
    },[editProduct,flagParentAttribute])
    
    return (
        <>
            <div className="bg-white flex flex-col rounded-lg border-2 border-gray-200">
                <div className="flex gap-5 p-5 items-center ml-5">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        Product Data
                    </label>
                    <div>
                        <Select
                            classNamePrefix="select"
                            //defaultValue={userTypes[0]}
                            placeholder="Select"
                            value={selectProductOption}
                            onChange={(selectedOption) => {
                                setSelectProductOption(selectedOption)
                            }}
                            isDisabled={viewFlag?true:false}
                            styles={customStyles}
                            options={ProductSelectOption}
                            className="basic-single sm:text-sm md:w-[250px] "

                        />

                    </div>
                    {error?.productError && <p className="text-red-500 text-sm ">{error?.productError}</p>}
                </div>
                <div class="flex flex-col md:flex-row">

                    <ul class="basis-2/12 flex flex-row md:flex-col items-center md:items-start  md:pl-5 md:pb-5  md:space-y-2 text-sm font-medium  border-[#ebac78] md:border-r-2 border-t-2">
                        {
                            navigationFilter.map((ele, index) => {
                                return (
                                    <>
                                        <li onClick={() => navHandler(ele.id)} key={index}>
                                            <Link to="#"
                                                //className="inline-flex justify-center items-center px-4 py-3 text-black rounded-lg active w-full " 
                                                className={classNames(
                                                    ele.id === navId
                                                        ? 'text-[#a96a37]'
                                                        : 'text-black',
                                                    'inline-flex justify-center items-center px-4 py-3  rounded-lg active w-full'
                                                )}
                                            >

                                                {ele.name}
                                            </Link>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                    <div class="basis-10/12 p-5  text-medium  w-full border-t-2 border-t-[#ebac78]">
                        {
                            navId === 1 &&
                            <div className="flex flex-col gap-5 ">
                                <div className="grid grid-cols-4 items-center">

                                    <label className="text-sm text-gray-500 col-span-4 xl:col-span-1">SKU</label>
                                    <input type="text"
                                        name="p_inv_sku"
                                        readOnly
                                        value={values.p_inv_sku}
                                        disabled={viewFlag?true:false}
                                        // onChange={(event) => {
                                        //     handleChange(event);
                                        // }}
                                        placeholder="SKU Value" className="block w-full col-span-4 xl:col-span-1  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78] sm:text-sm sm:leading-6" />
                                </div>
                                <div className="grid grid-cols-4 items-center">

                                    <label className="text-sm text-gray-500 w-full col-span-4 xl:col-span-1 ">Manage stock</label>
                                    <div className="flex gap-2 items-center w-full col-span-4 xl:col-span-3">
                                        <input type="checkbox"
                                            name="p_inv_manage_stock"
                                            checked={values.p_inv_manage_stock}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            disabled={viewFlag?true:false}
                                            placeholder=""
                                            className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                        <span className="text-gray-500 text-sm">Enable stock management at product level</span>
                                    </div>
                                </div>
                                {
                                    values.p_inv_manage_stock === true &&
                                    <div className="grid grid-cols-4 items-center">

                                        <label className="text-sm text-gray-500 col-span-4 xl:col-span-1">Stock quantity</label>
                                        <input type="number"
                                            name="p_inv_stock_quantity"
                                            value={values.p_inv_stock_quantity}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            disabled={viewFlag?true:false}
                                            placeholder="" className="block col-span-4 xl:col-span-1   rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                    </div>
                                }
                                <div className="grid grid-cols-4 items-center">

                                    <label className="text-sm text-gray-500 col-span-4 xl:col-span-1">Sold individually</label>
                                    <div className="flex gap-2 items-center col-span-4 xl:col-span-3">
                                        <input type="checkbox"
                                            name="p_inv_sold_individually"
                                            checked={values.p_inv_sold_individually}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            disabled={viewFlag?true:false}
                                            placeholder=""
                                            className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                        <span className="text-gray-500 text-sm ">Enable this to only allow one of this item to be bought in a single order</span>
                                    </div>
                                </div>

                            </div>
                        }
                        {
                            navId === 2 &&
                            <div className="flex flex-col gap-5 w-full">
                                <div className="flex  items-center w-full">
                                    <div className="w-[300px]">
                                        {/* {JSON.stringify(parentAttribute)} */}
                                        <Select
                                            classNamePrefix="select"
                                            isMulti
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={parentAttribute}
                                            isDisabled={viewFlag?true:false}
                                            className="basic-single sm:text-sm w-full "
                                            options={attributesOption?.length > 0 ? attributesOption : []}
                                            styles={customStyles}
                                            onChange={handleSelectAttributeChange}
                                        />
                                    </div>
                                </div>

                                {
                                    parentAttribute?.length > 0 && parentAttribute.map((ele, ind) => {
                                        console.log("parentAttribute", parentAttribute)
                                        return (
                                            <>
                                                <hr class="h-px my-2 bg-gray-300 border-0"></hr>
                                                <div className="flex flex-col gap-5">
                                                    <div className="flex flex-col gap-y-2">
                                                        <div className="flex items-center">
                                                            <h6 className="text-sm mr-2">{ele?.label}</h6>
                                                            <div className="w-[300px]">
                                                                <Select
                                                                    classNamePrefix="select"
                                                                    isMulti
                                                                    //defaultValue={userTypes[0]}
                                                                    placeholder="Select"
                                                                    //value={ele?.childDataArr || []}
                                                                    isDisabled={viewFlag?true:false}
                                                                    value={selectChildValue.keystatic?.find(item => item.p_product_attribute_id === ele?.p_product_attribute_id)?.data || []}
                                                                    className="basic-single sm:text-sm"
                                                                    options={ele?.childAttriOption?.length > 0 ? ele?.childAttriOption : []}
                                                                    styles={customStyles}
                                                                    onChange={(selectedOption) => handleChildAttribute(selectedOption, ele?.p_product_attribute_id, ind)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                selectProductOption?.label === "Variation Product" && ele?.p_child_attributes?.length === 0 && (
                                                                    <div className="flex gap-x-2 items-center">
                                                                        <input type="checkbox" 
                                                                        // checked={variationCheck[ind]?.checked}
                                                                        disabled={viewFlag?true:false}
                                                                        checked={variationCheck.find(item => item.parentId === ele?.p_product_attribute_id)?.checked || false} 
                                                                         onChange={(e) => {
                                                                            handleVariationCheck(e.target.checked, ind, ele?.p_product_attribute_id)
                                                                        }}
                                                                            className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                        <h4 className="text-sm">used for variation</h4>
                                                                    </div>)
                                                            }

                                                        </div>

                                                    </div>
                                                    {
                                                        selectChildValue?.keystatic && selectChildValue?.keystatic.map((item, index) => {
                                                            const childData = item?.p_product_attribute_id === ele.p_product_attribute_id ? item : []
                                                            console.log("childData", childData)

                                                            return (
                                                                <>
                                                                    {
                                                                        childData?.data?.map((dd) => {
                                                                            let childValue = dd?.p_child_attribute_id
                                                                            const inputValue = childInputValue.find(cv => cv.childId === dd.p_child_attribute_id)?.inputValue || '';
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        childValue ?
                                                                                            <div className="flex flex-col border-[1.5px] border-[#ebac78] rounded-md bg-gray-100">
                                                                                                <div className="flex gap-5 items-center border border-b-[#ebac78] p-1" >
                                                                                                    <h2 className="font-semibold text-black text-sm">{dd?.p_child_attribute_name}</h2>
                                                                                                </div>
                                                                                                <div className="flex p-3 items-center">
                                                                                                    <div className="basis-2/5 flex flex-col gap-2">
                                                                                                        <label className="text-sm">Child Attribute : <span className="text-sm font-medium">{dd?.p_child_attribute_name}</span></label>

                                                                                                    </div>
                                                                                                    <div className="basis-3/5">
                                                                                                        {
                                                                                                            dd?.p_is_storable === "Y" ?
                                                                                                                <>
                                                                                                                    <div className="flex gap-2 items-center">
                                                                                                                        <label className="text-sm ">Values</label>
                                                                                                                        <Select
                                                                                                                            classNamePrefix="select"
                                                                                                                            isMulti
                                                                                                                            //defaultValue={userTypes[0]}
                                                                                                                            placeholder="Select"
                                                                                                                            //value={selectChildValue}
                                                                                                                            isDisabled={viewFlag?true:false}
                                                                                                                            className="basic-single sm:text-sm w-[250px]"
                                                                                                                            //options={item?.getChildValueList?.length > 0 ? item?.getChildValueList : []}
                                                                                                                            styles={customStyles}
                                                                                                                        //onChange={(selectedOption) => getChildValueList(selectedOption, item)}
                                                                                                                        />

                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <div>
                                                                                                                        <label className="text-sm mr-2">Values</label>
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            value={inputValue}
                                                                                                                            disabled={viewFlag?true:false}
                                                                                                                            onChange={(e) => handleInputChange(ele?.p_product_attribute_id, dd?.p_child_attribute_id, e.target.value)}

                                                                                                                            className="w-[250px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm " />

                                                                                                                    </div>
                                                                                                                </>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : <></>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </>
                                        )
                                    })

                                }



                            </div>
                        }
                        {
                            navId === 3 && selectProductOption?.value === 2 && attributeData?.length > 0 && attributeData?.some(ele => ele?.variationCheck === true) && variationCheck?.length > 0 &&
                            <>
                                {
                                    loopVariation?.length > 0 && loopVariation?.map((item, index) => {
                                        if (variationCheck.some(pp=>pp?.parentId===item?.p_attribute_key_id && item?.variationCheck===pp?.checked)) {
                                            return (
                                                <>
                                                    {
                                                        item?.data?.map((dd, childIndex) => {
                                                            const formItem = variationForm.find(cc => cc?.childAttributeId === dd?.p_attribute_value_id);
                                                            return (
                                                                <>

                                                                    <div className="my-2">
                                                                        <div className="flex flex-col border-[1.5px] border-[#ebac78] rounded-md bg-gray-100 ">
                                                                            <div className="flex gap-5 items-center border border-b-[#ebac78] p-1" >
                                                                                <h2 className="font-semibold text-black text-sm">{dd?.p_attribute_value_name}</h2>
                                                                            </div>
                                                                            <div className="flex flex-col gap-5 items-center p-4">

                                                                                <div className="grid grid-cols-4 items-center">
                                                                                    <label className="text-sm text-gray-500 ">Regular price</label>
                                                                                    <input type="number"
                                                                                        min="0"
                                                                                        value={formItem?.price}
                                                                                        disabled={viewFlag?true:false}
                                                                                        onChange={(e) => handleVariationForm(dd?.p_attribute_value_id, 'price', e.target.value, dd)}
                                                                                        placeholder="Regular price" className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                                                                </div>
                                                                                <div className="grid grid-cols-4 items-center">
                                                                                    <label className="text-sm text-gray-500 ">Stock quantity</label>
                                                                                    <input type="number"
                                                                                        min="0"
                                                                                        value={formItem?.stock}
                                                                                        disabled={viewFlag?true:false}
                                                                                        onChange={(e) => handleVariationForm(dd?.p_attribute_value_id, 'stock', e.target.value, dd)}
                                                                                        placeholder="Stock quantity" className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                                                                </div>
                                                                                <div className="grid grid-cols-4 items-center">
                                                                                    <label className="text-sm text-gray-500 ">Description</label>
                                                                                    <input type="text"

                                                                                        value={formItem?.description}
                                                                                        disabled={viewFlag?true:false}
                                                                                        onChange={(e) => handleVariationForm(dd?.p_attribute_value_id, 'description', e.target.value, dd)}
                                                                                        placeholder="" className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>

                                            )
                                        }
                                    })

                                }
                            </>
                        }
                        {
                            navId === 4 &&
                            <div className="flex flex-col gap-5 ">
                                <div className="grid grid-cols-4 items-center w-full">
                                    <label className="text-sm text-gray-500 whitespace-nowrap col-span-2 xl:col-span-1">Listing Price</label>
                                    <input type="number" name="p_gen_regular_price"
                                        value={values.p_gen_regular_price}
                                        disabled={viewFlag?true:false}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}

                                        placeholder="Regular Price" className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                </div>
                                <div className="grid grid-cols-4 items-center w-full">
                                    <label className="text-sm text-gray-500 whitespace-nowrap col-span-2 xl:col-span-1">Sale price</label>
                                    <input type="number" name="p_gen_sale_price"
                                        value={values.p_gen_sale_price}
                                        disabled={viewFlag?true:false}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}

                                        placeholder="Sale price" className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                </div>
                                <div className="grid grid-cols-4 items-center w-full">
                                    <label className="text-sm text-gray-500  col-span-2 xl:col-span-1">Original Purchase Price</label>
                                    <input type="number" name="p_original_purchase_price"
                                        value={values.p_original_purchase_price}
                                        disabled={viewFlag?true:false}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}

                                        placeholder="Purchase Price" className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400  ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]  sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        

        </>

    )
}

export default Attributes;