import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts"
const ColumnChart = (props) => {
    let seriesData = []
    props?.lineData?.data?.map((e) => {
    if(Object.keys(e)[0] != undefined)
    {
      seriesData.push({
        name: Object.keys(e)[0],
        data: Object.values(e)[0]
      })
    }
    })
    const state = {
        series: props.lineData ? seriesData : [],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
          },
          xaxis: {
            categories: props.lineData ? props?.lineData?.date : [],
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50
          },
        },
      };

    

    return (
        <div  className="w-full">
            <Chart
                options={state.options}
                series={state.series}
                type="bar"
                height="400"
                className="w-full h-full "
                id="chart"
            />
        </div>
    )
}

export default ColumnChart