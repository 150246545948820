import Api from './Api';
const AuthServices = {
    async LoginForm(payload) {
        
        try {
            const res = await Api().post('api/login-user',payload);
           
            if (res && res.data.status) {
                return res;
            } else {
                return res;
            }
        } catch (error) {
            console.log("error",error)
            return error;
        }
    },
}


export default AuthServices