import React from "react";
import { SortDownIcon, SortUpIcon, SortIcon } from "../../assests/icons/sorting";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useAsyncDebounce,
    usePagination,
} from 'react-table';

const SummaryTable = ({ columns, data, table_id, printRef, zoom, pagination, goToThePage, loading, pageSizeState, setPageSizeState })=>{
    const {state,
        pageOptions,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        preGlobalFilteredRows,
        setGlobalFilter,
        setPageSize,} = useTable({
                columns,data
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
)
    return(
        <>
           <div id={table_id} ref={printRef} className="mt-4 ">
                <div className="-mx-4 overflow-x-auto enable-scrollbar sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table  {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50 whitespace-nowrap">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props
                                                <th
                                                    scope="col"
                                                    className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                    {...column.getHeaderProps({
                                                        style: { width: column.width },
                                                    })}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        {column.render('Header')}
                                                        
                                                        <span>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <SortDownIcon className="w-4 h-4 text-gray-400" />
                                                                ) : (
                                                                    <SortUpIcon className="w-4 h-4 text-gray-400" />
                                                                )
                                                            ) : (
                                                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                                            )}
                                                        </span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white divide-y divide-gray-200"
                                >
                                    {page?.length > 0 ? (page.map((row, i) => {

                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className={`px-6 py-4 whitespace-nowrap text-left `}
                                                            role="cell"
                                                        >
                                                            {cell.column.Cell.name === 'defaultRenderer' ? (
                                                                <div className="text-sm text-gray-500">
                                                                    {cell.render('Cell')}
                                                                </div>
                                                            ) : (
                                                                cell.render('Cell')
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })) : (

                                        <>{headerGroups.map((headerGroup) => (
                                            <tr>
                                                <td className='p-5 text-center whitespace-nowrap w-full text-gray-500' colSpan={headerGroup.headers.length}>No Data Found</td>
                                            </tr>
                                        ))}</>


                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryTable