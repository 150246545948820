import React, { useState } from "react";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import { customStyles } from "../../../helper/customStyles";

const AddChildAttribute = () => {

    const options = [
        { value: '1', label: 'First' },
        { value: '2', label: 'Second' }
    ];

    const [data, setdata] = useState({
        p_child_attribute_name: "",
        p_product_attribute_id: null,
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null
    });

    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const navigate = useNavigate()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            const value = inputValue.trim();
            if (!items.includes(value)) {
                setItems([...items, value]);
            }
            setInputValue('');
        }
    };

    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };



    const handleChange = (e) => {
        e.preventDefault();
        setdata((data) => ({ ...data, [e.target.name]: e.target.value }))
        // console.log(e.target.value)
        // console.log(e.target.name)
    }

    const handleDropdown = (val) => {
        console.log(typeof (data.value))
        setdata(data => ({ ...data, p_product_attribute_id: val.value }))

    }


    const submitform = async () => {
        // console.log(data, "this is data")

        let finaldata = data;
        finaldata = { ...finaldata, items }

        let resp = await MasterServices.addAttributeChild(finaldata)
        if (resp.data.success) {
            console.log(resp.data)
            setdata({
                "p_child_attribute_name": "",
                "p_product_attribute_id": null,
                "slug": null,
                "m_meta_title": null,
                "m_meta_desc": null,
                "m_meta_tags": null
            });
            setItems([]);
            setInputValue("")
        }
        else {
            console.log(resp.data)
        }
    }

    const clearForm = ()=>{
        navigate("/childAttribute")
    }
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Child Attribute</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="">
                            <input
                                value={data.p_child_attribute_name}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="p_child_attribute_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Parent Attribute
                        </label>
                        <div>
                            <Select
                                onChange={handleDropdown}
                                classNamePrefix="select"
                                placeholder="Select"
                                name="p_product_attribute_id"
                                className="basic-single sm:text-sm"
                                options={options}
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value={data.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="slug"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>
                    {/* <div className="col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Description"
                            />

                        </div>
                    </div> */}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value={data.m_meta_title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_title"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Title"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta keywords
                        </label>
                        <div className="">
                            <input
                                value={data.m_meta_tags}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_tags"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Tags"
                            />

                        </div>



                    </div>

                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Values
                        </label>
                        <div className="">

                            <div className="relative flex flex-wrap items-center">
                                {items.map((item, index) => (
                                    <div key={index} className="flex items-center sm:text-sm bg-gray-200 rounded px-2 py-1 m-1">
                                        <span>{item}</span>
                                        <button className="ml-1 sm:text-sm text-red-500 hover:text-red-700" onClick={() => removeItem(index)}>&times;</button>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    className="flex-grow rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Type and Press Enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>

                        </div>


                    </div>

                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value={data.m_meta_desc}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_desc"
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        // onClick={clearForm}
                        onClick={submitform}
                        type="submit"
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddChildAttribute