import React,{useState} from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { tag_columns } from "../../../components/tables/tableheader";
import Spinner from "../../../components/Spinner";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};


const TagPage = () => {
    const pages = [{ title: "Tags", href: "/tag-page", module_id: 1 }];
    const [loading, setLoading] = useState(false)

    const TagData = [
        {
            name: "xyzdb",
            description: "bkjfhd nkjcdfdnkjnmd j",
            slug: 'women',
            count: 4,

        }
    ]
    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Tag List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the tags.</p>
                </div>
                <Link

                    to="/add-tag"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD TAG
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Tag
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="date"
                              
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="date"
                                
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                styles={customStyles}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                           
                            />

                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        // onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    //onClick={downloadUserDataExcel}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading?
                <Spinner />
                :
            <Table
                columns={tag_columns()}
                data={TagData}
            />
            }
        </>
    )
}

export default TagPage