import React from "react";

const AddTag = () => {
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Tag</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md h-40 border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta Title"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta keywords
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta keywords"
                            />

                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta Description"
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddTag