import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import { user_columns } from "../../../components/tables/tableheader";
import { customStyles } from "../../../helper/customStyles";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";


const Users = () => {
    const pages = [{ title: "Users", href: "/user-page", module_id: 1 }];
    const [userData, setUserData] = useState([]);
    const [filterUserData, setFilterUserData] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectUserType, setSelectUserType] = useState(null)

    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })


    useEffect(() => {
        const fetchData = async () => {
            await getAllUsers()
        }

        fetchData();
    }, [])

    const userTypeOption = [
        {
            label: "Admin",
            value:3,
        },
        {
            label: "Users",
            value: [1,2],
        },

    ]

    

    const getAllUsers = async () => {
        try {
            const response = await MasterServices.getAllUsers();
            //console.log("getAllUsers",response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setUserData(response?.data?.data);
                setFilterUserData(response?.data?.data)
                setLoading(false)
            } else {
                setUserData([]);
                setLoading(false)
            }
        }
        catch (error) {
            toast.error(error?.data?.message)
            setLoading(false)
        }
    }

    useEffect(() => {

        // u_user_created_at
        if (dateInput?.startDate || dateInput?.endDate || selectUserType?.value ) {

            const filterData = filterUserData?.filter((ele) => {
                let dateFilter = moment(ele?.u_user_created_at).format("YYYY-MM-DD");

                let startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                let endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                let userType = selectUserType?.value;
                //console.log("userType",userType)
                if (userType) {
                    if (parseInt(ele?.u_user_role_id) === parseInt(userType) || ( (ele?.u_user_role_id) === (parseInt(userType[0]) && parseInt(userType[1])) )) {
                        if (!startDate && !endDate) {
                            return true
                        }
                        return (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter)
                    }    
                }
                else {
                    return (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter)
                }

                return false
            })

            // console.log("filterData", {
            //     filterData,
            //     dateInput,
            //     selectUserType
            // })
            if (filterData?.length > 0) {
                setUserData(filterData)
            } else {
                setUserData([])
            }
        }

    }, [dateInput, selectUserType,filterUserData])

    const clearForm = ()=>{
        
        setSelectUserType(null)
        setDateInput({
            startDate: "",
            endDate: ""
        })

        setUserData(filterUserData)
    }

    //console.log("selectUserType", selectUserType)
    const downloadExcelBtn = ()=>{

       // console.log("userData",userData)

        const filterData = userData?.map((ele)=>{
            return{
                Id:ele?.u_user_id,
                FullName :`${ele?.u_user_first_name} ${ele?.u_user_last_name}`,
                Email:ele?.u_user_email,
                CreatedAt : moment(ele?.u_user_created_at).format("YYYY-MM-DD")
            }
        })

        downloadSheet(filterData,"users")

        // console.log("filterData",filterData)
    }
    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Users List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the users.</p>
                </div>
                <Link

                    to="/add-user"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-lg  hover:bg-[#3a4b43] hover:text-white "
                >
                    ADD USER
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            User Type
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                placeholder="Select"
                                value={selectUserType}
                                onChange={(selectedOption) => {
                                    setSelectUserType(selectedOption)
                                }}
                                options={userTypeOption}
                                // name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput?.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput?.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>

                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={()=>downloadExcelBtn()}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={user_columns()}
                        data={userData}
                    />
            }
        </>
    )
}

export default Users