import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select"
import { PlusCircleIcon, ArrowDownCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import SummaryTable from "../../../components/tables/summarytable";
import { summary_columns } from "../../../components/tables/tableheader";
import { useFormik } from "formik";
import { parsePath, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import {
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState
} from "react-country-state-city";
import { Country, State, City } from 'country-state-city';
import "react-country-state-city/dist/react-country-state-city.css";
import MasterServices from "../../../ApiServices/MasterServices";
import { ToastContainer, toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import moment from "moment";
import debounce from "../../../helper/debounce"
import UseWallet from "../../../components/UseWallet";



const AddOrder = () => {
    const [shippingFlag, setShippingFlag] = useState(true)
    const [billingFlag, setBillingFlag] = useState(true);
    const [addressData, setAddressData] = useState([])
    const [loading, setLoading] = useState(true)
    const [shipmentTrackFlag, setShipmentTrackFlag] = useState(true)
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});

    const [shipCountry, setShipCountry] = useState({})
    const [shipState, setShipState] = useState({})
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [compnayName, setCompanyName] = useState("")
    const [addressOne, setAddressOne] = useState("")
    const [addressTwo, setAddressTwo] = useState("")
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState();
    // const [email, setEmail] = useState("");
    const [phone, setPhone] = useState();
    //const [transactionId, setTransactionId] = useState();
    const [customerData, setCustomerData] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [selectCustomer, setSelectCustomer] = useState({})
    const [selectPaymentMethod, setSelectPaymentMethod] = useState({})
    //const [selectShipPayMethod, setSelectShipPayMethod] = useState({})
    const [orderStatus, setOrderStatus] = useState({})
    const [orderStatusOption, setOrderStatusOption] = useState([])
    const [productData, setProductData] = useState([])
    const [productOption, setProductOption] = useState([])
    const [selectProduct, setSelectProduct] = useState([])
    const [selectMarkOrder, setSelectMarkOrder] = useState({})
    const [selectShipProvider, setSelectShipProvider] = useState({})
    const [selectOrderAction, setSelectOrderAction] = useState({})
    const [countries, setCountries] = useState([]);
    const [billstateOption, setBillStateOption] = useState([]);
    const [shipStateOption, setShipStateOption] = useState([])
    const [editOrder, setEditOrder] = useState([])
    const navigate = useNavigate();
    const [couponAmount, setCouponAmount] = useState(0);
    const [walletAmount, setWalletAmount] = useState(0)
    const [couponData, setCouponData] = useState([]);
    const [couponCode, setCouponCode] = useState("");
    const [errorCouponMessage, setErrorCouponMessage] = useState(true);
    let [totalOrderCoupon, setTotalOrderCoupon] = useState(0);
    const [customerProviderNote, setCustomerProviderNote] = useState("")
    const [summaryDataList, setSummaryDataList] = useState([])
    const { order_id } = useParams();
    const [openWalletFlag, setOpenWalletFlag] = useState(false);
    const [couponRemoveFlag, setCouponRemoveFlag] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [productTotal, setProductTotal] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const [customAmount, setCustomAmount] = useState(0);
    const [customAmountFlag, setCustomAmountFlag] = useState(true);
    const [customPrice, setCustomPrice] = useState(0);
    const [itemSubTotal, setItemSubTotal] = useState(0)
    const [addressId, setAddressId] = useState({
        m_billing_address_id: null,
        m_shipping_address_id: null
    })

    const formData = {

        b_firstName: "",
        b_lastName: "",
        b_companyName: "",
        b_addressOne: "",
        b_addressTwo: "",
        b_city: "",
        b_zipCode: "",
        b_email: "",
        b_phone: "",
        b_transactionId: "",
        b_billing_date: "",
        coupon: "",
        tracking_number: "",
        tracking_ship_date: ""

    }
    const [initialValues, setInitialValues] = useState(formData);

    useEffect(() => {
        const fetchData = async () => {
            await getAllOrderStatus()
        }
        fetchData()
    }, [])

    const summaryData = [
        {
            productname: "xyzdf",
            sku: 122,
            cost: 2000,
            quantity: 2,
            total: 400000
        }
    ]

    const getAllOrderStatus = async () => {
        try {

            const response = await MasterServices.getOrderStatus();
            //console.log("response", response)
            if (response?.data?.data?.length > 0) {
                const filter = response?.data?.data.map(ele => ({
                    label: ele?.m_order_status_value,
                    value: ele?.m_order_status_id

                }))
                setOrderStatusOption(filter)
            }

        } catch (error) {
            toast.error(error)
        }
    }



    const paymentMethod = [

        {
            value: 1,
            label: "Payu",
        },
        {
            value: 2,
            label: "COD",
        },
        {
            value: 3,
            label: "Stripe",
        },
        {
            value: 4,
            label: "Others",
        },
    ]

    const markOrder = [
        {
            value: 1,
            label: "Shipped",
            key: "shippped"
        },
        {
            value: 2,
            label: "Partially shipped",
            key: "partially"
        }
    ]

    // const shippingProvider = [
    //     {
    //         value: 1,
    //         label: "abc"
    //     },
    //     {
    //         value: 2,
    //         label: "xyz"
    //     }
    // ]

    const shippingProvider = [
        {
            label: 'US',
            options: [
                { label: 'DHL US', value: 'DHL US' },
                { label: 'Associated Global Systems', value: 'Associated Global Systems' },
                { label: 'DHL Parcel', value: 'DHL Parcel' },
                { label: 'TNT', value: 'TNT' },
            ]
        },
        {
            label: 'Austria',
            options: [
                { label: 'DPD Austria', value: 'DPD Austria' },
                { label: 'post.at', value: 'post.at' },
                { label: 'DHL Parcel', value: 'DHL Parcel' },
                { label: 'TNT', value: 'TNT' },
            ]
        },
        {
            label: 'Canada',
            options: [
                { label: 'Canada Post', value: 'Canada Post' },
                { label: 'Canpar', value: 'Canpar' },

            ]
        },
        {
            label: 'Chile',
            options: [
                { label: 'Correos Chile', value: 'Correos Chile' }
            ]
        },
        {
            label: 'China',
            options: [
                { label: 'China Post', value: 'China Post' },
                { label: 'EMS', value: 'EMS' },
            ]
        },
        {
            label: 'India',
            options: [
                { label: 'By Hand Delivery', value: 'By Hand Delivery' },

            ]
        },
    ];



    const orderAction = [
        {
            value: 1,
            label: "Email invoice / order details to customer"
        },
        {
            value: 2,
            label: "Resend new order notification"
        },
        {
            value: 3,
            label: "Regenerate download permissions"
        },
        {
            value: 4,
            label: "Export Tracking to PayPal"
        },
        {
            value: 5,
            label: "Recalculate order cashback"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            await getUsers();
            await getProducts()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (customerData?.length > 0 && productData?.length > 0) {
            setLoading(false);
        }

        if (customerData?.length > 0 && selectCustomer?.value) {
            customerData.forEach((ele) => {
                if (parseInt(ele?.u_user_id) === selectCustomer?.value) {
                    setWalletBalance(ele?.m_wallet_balance)
                }
            })

        }
    }, [customerData, productData, selectCustomer]);

    const getSingleOrder = async (id) => {
        try {

            const response = await MasterServices.getOrderById(id);
            console.log("getSingleOrder", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditOrder(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }

    }

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        //console.log("response", response?.data?.data)

        if (response?.data?.data?.length > 0) {
            const optionData = []
            //console.log("getUsers", response?.data?.data)
            setCustomerData(response?.data?.data)
            // const optiondata = response?.data?.data.map((ele) => ({
            //     value: ele?.u_user_id,
            //     fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
            //     email: ele?.u_user_email
            // }))
            response?.data?.data.forEach((ele) => {
                // if(ele?.m_wallet_balance){
                //     setWalletBalance(ele?.m_wallet_balance)
                // }

                optionData.push({
                    value: ele?.u_user_id,
                    fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
                    email: ele?.u_user_email
                })

            })
            setCustomerOption(optionData)
        } else {
            setCustomerData([])
        }
    }

    const getProducts = async () => {
        try {

            const response = await MasterServices.getAllProduct();
            //console.log("getProducts", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setProductData(response?.data?.data)

                let dataoption = response?.data?.data?.map((ele)=>{
                    if(ele?.p_stok_quantity>0){
                        return {
                            ...ele, 
                            value: ele?.p_porduct_id, 
                            label: ele?.p_product_sku
                        };
                    }
                    return null
                }).filter(Boolean);

                console.log('dataoption',dataoption)
                if (dataoption?.length > 0) {
                    setProductOption(dataoption)
                }


            }
        } catch (error) {
            toast.error("error in getProducts")
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (order_id) {
                await getSingleOrder(order_id)
            }
        }
        fetchData()
    }, [order_id])

    const DateFormatter = (bdate) => {
        // const dateStr = "2024-06-13T00:00:00.000Z";
        const date = new Date(bdate);

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getUTCFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        console.log("formattedDate", formattedDate)
        return formattedDate
    }



    useEffect(() => {
        if (order_id && editOrder?.length > 0) {
            editOrder.forEach((item) => {

                if (item?.address?.length > 0) {
                    item?.address.forEach((ele) => {

                        if (ele?.u_address_type === "billing") {
                            setInitialValues({
                                ...values,
                                b_firstName: item?.m_billing_name?.split(' ')[0],
                                b_lastName: item?.m_billing_name?.split(' ')[1],
                                b_companyName: item?.m_billing_company_name,
                                b_addressOne: ele?.u_address_value_1,
                                b_addressTwo: ele?.u_address_value_2,
                                b_city: ele?.u_city,
                                b_zipCode: ele?.u_pin_number,
                                b_email: item?.m_billing_email,
                                b_phone: item?.m_billing_phone,

                            })
                            if (ele?.u_country || ele?.u_state) {
                                const country = Country.getAllCountries().find(
                                    (c) => c?.name === ele?.u_country
                                );

                                const allState = State.getStatesOfCountry(country?.isoCode);
                                const state = allState.find((s) => s.name === ele?.u_state);
                                //console.log("countryJay",state)
                                setCountry(country)
                                setState(state)

                            }

                            if (ele?.u_address_id) {
                                setAddressId((prev) => ({
                                    ...prev,
                                    m_billing_address_id: ele?.u_address_id
                                }))
                            }

                        }
                        if (ele?.u_address_type === "shipping") {
                            setFirstName(item?.m_shipping_name?.split(' ')[0]);
                            setLastName(item?.m_shipping_name?.split(' ')[1]);
                            setCompanyName(item?.m_shipping_company_name);
                            setAddressOne(ele?.u_address_value_1);
                            setAddressTwo(ele?.u_address_value_2);
                            setCity(ele?.u_city);
                            setZipCode(ele?.u_pin_number);
                            //setEmail(ele?.u_email_address);
                            setPhone(item?.m_shipping_phone)
                            setShipCountry(prev => ({
                                ...prev,
                                label: ele?.u_country
                            }))

                            if (ele?.u_country) {
                                if (ele?.u_country || ele?.u_state) {
                                    const country = Country.getAllCountries().find(
                                        (c) => c?.name === ele?.u_country
                                    );

                                    const allState = State.getStatesOfCountry(country?.isoCode);
                                    const state = allState.find((s) => s.name === ele?.u_state);
                                    // console.log("countryJay",state)
                                    setShipCountry(country)
                                    setShipState(state)
                                }

                            }
                            if (ele?.u_address_id) {
                                setAddressId((prev) => ({
                                    ...prev,
                                    m_shipping_address_id: ele?.u_address_id
                                }))
                            }
                        }
                        else {

                            setInitialValues((prev) => ({
                                ...prev,
                                b_transactionId: item?.m_transction_id,

                                coupon: item?.m_coupon_code,
                                b_billing_date: DateFormatter(item?.m_billing_date),
                                tracking_number: item?.m_order_tracking_number,
                                tracking_ship_date: DateFormatter(item?.m_tarcking_ship_date),
                            }))
                        }
                    })
                }
                setOrderStatus(prev => ({
                    ...prev,
                    label: item?.m_order_status

                }))


                if (parseInt(item?.m_coupon_amount)) {
                    const m_coupon_amount = item?.m_coupon_amount === null || !item?.m_coupon_amount ? 0 : parseInt(item?.m_coupon_amount)
                    setCouponAmount(parseInt(m_coupon_amount))
                }
                if (parseInt(item?.m_wallet_points_applied)) {
                    const walletAmountData = item?.m_wallet_points_applied === null || !item?.m_wallet_points_applied ? 0 : parseInt(item?.m_wallet_points_applied)
                    setWalletAmount(parseInt(walletAmountData))
                }
                if (item?.m_coupon_code) {
                    setCouponRemoveFlag(false)
                }

                if (item?.payment_method) {
                    paymentMethod?.length > 0 && paymentMethod.forEach((ele) => {
                        if (ele?.label === item?.payment_method) {
                            setSelectPaymentMethod({
                                label: ele?.label,
                                value: ele?.value
                            })
                        }
                    })
                }

                if (item?.order_action) {
                    orderAction?.length > 0 && orderAction.forEach((ele) => {
                        if (ele?.label === item?.order_action) {
                            setSelectOrderAction({
                                label: ele?.label,
                                value: ele?.value
                            })
                        }
                    })
                }


                if (item?.m_shipped === "Y" || item?.m_partially_shipped === "Y") {
                    setSelectMarkOrder({
                        partially: item?.m_partially_shipped === "Y" ? true : false,
                        shippped: item?.m_shipped === "Y" ? true : false
                    })
                }

                if (item?.u_user_id) {

                    customerOption?.length > 0 && customerOption?.forEach((ele) => {
                        if (parseInt(ele?.value) === parseInt(item?.u_user_id)) {

                            setSelectCustomer({
                                value: ele?.value,
                                fullName: ele?.fullName,
                                email: ele?.email
                            })
                        }
                    })
                }

                if (item?.m_shipping_provider_id || item?.m_shipping_provider_name) {

                    shippingProvider?.forEach((ele) => {
                        ele?.options?.forEach((sp) => {
                            if (sp?.label === item?.m_shipping_provider_name) {
                                setSelectShipProvider({
                                    value: sp?.value,
                                    label: sp?.label
                                })
                            }

                        })
                    })

                }

                if (item?.customization_amount > 0) {
                    setCustomAmount(parseInt(item?.customization_amount))
                    setCustomAmountFlag(false)
                    setCustomPrice(parseInt(item?.customization_amount))
                }

                if (item?.p_product_id) {
                    //console.log("productOption", productOption)
                    const productIds = item?.p_product_id?.split(',').map(id => parseInt(id));
                    //console.log("productIds", productIds)
                    productOption?.length > 0 && productOption?.forEach((ele) => {
                        const productId = parseInt(ele?.p_porduct_id);

                        if (productIds?.includes(ele?.p_porduct_id)) {

                            
                            setSelectProduct((prev) => {
                                const isProductAlreadyAdded = prev.some(p => p.value === productId);
                                if (isProductAlreadyAdded) {
                                    return prev; 
                                }
                                return [
                                    ...prev,
                                    {
                                        ...ele,
                                        label: ele?.label,
                                        value: ele?.p_porduct_id
                                    }
                                ];
                            });
                            setSummaryDataList((prev) => {
                                const isProductAlreadyAdded = prev.some(p => p.value === productId);
                                if (isProductAlreadyAdded) {
                                    return prev; 
                                }
                                return [
                                    ...prev,
                                    {
                                        ...ele,
                                        label: ele?.label,
                                        value: ele?.p_porduct_id
                                    }
                                ];
                            });

                           
                        }
                    })
                }

            })
        }
    }, [editOrder, productOption, customerOption])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {

            const body = {
                ...values,
                s_firstName: firstName,
                s_lastName: lastName,
                s_compnayName: compnayName,
                s_addressOne: addressOne,
                s_addressTwo: addressTwo,
                s_city: city,
                s_zipCode: zipCode,
                //s_email: email,
                s_phone: phone,
                // s_transactionId: transactionId,
                s_shipCountry: shipCountry ? shipCountry?.name : "",
                s_state: shipState ? shipState?.name : "",

                customername: selectCustomer?.value ? selectCustomer?.value : null,
                orderStatus: orderStatus ? orderStatus.label : "",
                product: selectProduct?.length > 0 && selectProduct?.map((ele) => ele?.p_product_sku).join(','),
                p_product_id: selectProduct?.length > 0 && selectProduct?.map((ele) => ele?.p_porduct_id).join(','),
                m_shipping_provider_id: selectShipProvider?.value ? selectShipProvider?.value : "",
                m_shipping_provider_name: selectShipProvider?.label ? selectShipProvider?.label : "",

                Shipped: selectMarkOrder?.shipped ? true : false,
                Partially_shipped: selectMarkOrder?.partially ? true : false,

                orderAction: selectOrderAction?.label ? selectOrderAction.label : null,
                b_country: country ? country?.name : "",
                b_state: state ? state?.name : "",

                payment_method: selectPaymentMethod?.label ? selectPaymentMethod?.label : null,
                m_billing_address_id: addressId?.m_billing_address_id ? addressId?.m_billing_address_id : null,
                m_shipping_address_id: addressId?.m_shipping_address_id ? addressId?.m_shipping_address_id : null,
                customer_provider_note: customerProviderNote ? customerProviderNote : "",
                m_order_id: order_id ? parseInt(order_id) : null,
                m_total_order_price: itemSubTotal ? itemSubTotal : null,
                m_coupon_amount: couponAmount ? couponAmount : null,
                m_wallet_points_applied: walletAmount ? walletAmount : null,
                customization_amount: customPrice ? parseInt(customPrice) : 0,

            }

            console.log("body", body)

            if (editOrder?.length > 0 && order_id) {
                editOrderSubmit(body)
            } else {
                addOrderSubmit(body)
            }

        }
    })



    const editOrderSubmit = async (body) => {
        try {
            const response = await MasterServices.editOrders(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Order Edit Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            toast.error("An error occurred while editing the order")
        }
    }

    const addOrderSubmit = async (body) => {
        try {
            const response = await MasterServices.addOrders(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Order Added Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            toast.error("An error occurred while adding the order")
        }
    }



    const copyBillingHandle = () => {


        setFirstName(values.b_firstName)
        setLastName(values.b_lastName);
        setCompanyName(values.b_companyName);
        setAddressOne(values.b_addressOne);
        setAddressTwo(values.b_addressTwo);
        setCity(values.b_city);
        setZipCode(values.b_zipCode);
        //setEmail(values.b_email);
        setPhone(values.b_phone);
        //setTransactionId(values.b_transactionId)

        setShipCountry(country)
        setShipState(state)
        //setSelectShipPayMethod(selectPaymentMethod)
    }

    const clearForm = () => {
        navigate("/order-page")

    }

    const handleMarkOrder = (name, isChecked) => {

        const updatedState = markOrder.reduce((acc, ele) => {
            acc[ele.key] = ele.key === name;
            return acc;
        }, {});

        console.log("updatedState", updatedState)
        setSelectMarkOrder(updatedState);

    }
    const totalHandle = (itemTotal, cpAmount, wAmount, customAmount) => {
        const indiaShippingPrice = 1000;
        const otherShippingPrice = 2000;
        console.log("totalhandle", {
            itemTotal: itemTotal,
            cpAmount: cpAmount,
            wAmount: wAmount,
            customAmount: customAmount
        })

        if (shipCountry?.name === "India") {
            // const result = stockQuantity === 0 || !stockQuantity ? (parseInt(productPrice || 0) + indiaShippingPrice + (customAmount) )  - (cpAmount) - (wAmount): (parseInt(productPrice) * parseInt(stockQuantity) + indiaShippingPrice + (customAmount)) - (cpAmount) - (wAmount) ;
            // totalOrderCoupon = result
            const result = ((itemTotal - cpAmount) + indiaShippingPrice + customAmount) - wAmount
            return result;

        }
        else {
            // const result = stockQuantity === 0 || !stockQuantity ? (parseInt(productPrice || 0) + otherShippingPrice + (customAmount))  - (cpAmount) - (wAmount) : (parseInt(productPrice) * parseInt(stockQuantity) + otherShippingPrice + (customAmount)) - (cpAmount) - (wAmount);
            // totalOrderCoupon = result
            const result = ((itemTotal - cpAmount) + otherShippingPrice + customAmount) - wAmount

            return result;
        }
    }



    const getAddress = async (id) => {
        try {
            const response = await MasterServices.getAddressById(id);
            console.log("getAddress", response)
            if (response?.data?.data?.length > 0) {
                setAddressData(response?.data?.data)
            } else {
                setAddressData([])
            }
        }
        catch (error) {
            console.log("getAddress", error)
        }
    }

    useEffect(() => {
        if (selectCustomer?.value) {
            getAddress(selectCustomer?.value)
        }
    }, [selectCustomer])

    const loadBillingHandle = () => {
        if (addressData?.length > 0) {
            addressData?.forEach((ele) => {
                if (ele?.u_address_type === "billing") {
                    const Name = ele?.u_user_name ? ele?.u_user_name?.split(" ") : ""
                    console.log("loadBillingHandle", Name)
                    setInitialValues(({
                        b_firstName: Name[0],
                        b_lastName: Name[1],
                        b_companyName: ele?.u_company_name,
                        b_addressOne: ele?.u_address_value_1,
                        b_addressTwo: ele?.u_address_value_2,
                        b_city: ele?.u_city,
                        b_zipCode: ele?.u_pin_number,
                        b_email: ele?.u_email_address,
                        b_phone: ele?.u_mobile_number,
                        coupon: ""

                    }))

                    if (ele?.u_country || ele?.u_state) {
                        const country = Country.getAllCountries().find(
                            (c) => c?.name === ele?.u_country
                        );

                        const allState = State.getStatesOfCountry(country?.isoCode);
                        const state = allState.find((s) => s.name === ele?.u_state);
                        //console.log("countryJay",state)
                        setCountry(country)
                        setState(state)

                    }
                }
            })
        }
    }

    const loadShippingHandle = () => {
        if (addressData?.length > 0) {
            addressData?.forEach((ele) => {

                if (ele?.u_address_type === "shipping") {
                    const Name = ele?.u_user_name ? ele?.u_user_name?.split(" ") : ""
                    console.log("loadBillingHandle", Name)
                    setFirstName(Name[0])
                    setLastName(Name[1]);
                    setCompanyName(ele?.u_company_name);
                    setAddressOne(ele?.u_address_value_1);
                    setAddressTwo(ele?.u_address_value_2);
                    setCity(ele?.u_city);
                    setZipCode(ele?.u_pin_number);
                    setPhone(ele?.u_mobile_number);

                    if (ele?.u_country || ele?.u_state) {
                        const country = Country.getAllCountries().find(
                            (c) => c?.name === ele?.u_country
                        );

                        const allState = State.getStatesOfCountry(country?.isoCode);
                        const state = allState.find((s) => s.name === ele?.u_state);
                        // console.log("countryJay",state)
                        setShipCountry(country)
                        setShipState(state)
                    }
                }
            })
        }
    }


    const applyCouponVoucher = async () => {

        setLoading(true)
        try {
            if (selectProduct?.length > 0 && shipCountry?.name) {


                const payload = {
                    m_voucher_code: couponCode,
                    amount: totalAmount,
                    date: moment().format("YYYY-MM-DD"),
                }
                //console.log("applyCouponVoucher", payload)
                const response = await MasterServices.applyCoupon(payload);
                console.log("applyCouponVoucher", response)
                if (response?.data?.success) {
                    toast.success("Coupon Applied")
                    setCouponRemoveFlag(false)
                    setCouponData(response?.data?.data);
                    const couponState = response?.data?.data;
                    const couponDiscount = couponState?.is_flat === "Y" ? parseInt(couponState?.discount_percentage) : (parseInt(couponState?.orignal_amount) / 100) * parseInt(couponState?.discount_percentage);
                    setCouponAmount(couponDiscount)
                    setLoading(false)
                } else {
                    toast.error(response?.data?.message)
                    setCouponRemoveFlag(false)
                    setCouponData([])
                    setLoading(false)
                }
            }
        }
        catch (error) {
            console.log("applyCouponVoucher", error);
            toast.error(error?.data?.message)
            setLoading(false)
        }
    }



    const removeCouponVoucher = () => {
        values.coupon = ""
        setCouponAmount(0)
        setCouponRemoveFlag(true);
        setCouponCode("")
    }




    useEffect(() => {
        if (selectProduct?.length > 0 && shipCountry?.name) {
            setErrorCouponMessage(false);
        }


        if (itemSubTotal || (couponAmount === 0 || couponAmount) || walletAmount > 0 || (customPrice === 0 || customPrice)) {

            let totalAmt = totalHandle(
                itemSubTotal,
                couponAmount,
                walletAmount,
                customPrice
            );
            setTotalAmount(totalAmt);
        }


        if (selectProduct?.length > 0) {

            const filterData = selectProduct?.map((ele) => {
                const stockQuantity = ele?.p_stok_quantity <= 0 ? 1 : ele?.p_stok_quantity;

                return parseInt(ele?.p_product_price) * parseInt(stockQuantity);

            });

            const totalPrice = filterData.reduce((acc, curr) => acc + curr, 0);
            setItemSubTotal(totalPrice)
            console.log("TotalProduct Price:", totalPrice);
        }
        if (selectProduct?.length <= 0) {
            setItemSubTotal(0)
        }
    }, [selectProduct, couponAmount, shipCountry, walletAmount, customPrice, itemSubTotal]);

    console.log("summaryDataList", summaryDataList)

    const applyCustomAmount = () => {
        if (customAmount) {

            setCustomPrice(parseInt(customAmount))
            setCustomAmountFlag(false)
        }
    }

    const removeCustomAmount = () => {
        setCustomPrice(0)
        setCustomAmount(0)
        setCustomAmountFlag(true)
    }

    console.log("selectProduct", selectProduct)
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{order_id ? "Edit Order" : "Add Order"}</h1>
            </div>
            <div className="py-5">
                <form className="space-y-5" onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                }}>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200" >
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer
                            </label>
                            <div className="">
                                <Select
                                    classNamePrefix="select"

                                    value={selectCustomer}
                                    getOptionLabel={(option) => option?.fullName ? `${option?.fullName} (${option?.email})` : ""}
                                    getOptionValue={(option) => option.value}

                                    onChange={(selectOption) => {
                                        console.log("setSelectCustomer", selectOption)
                                        setSelectCustomer(selectOption)
                                    }
                                    }
                                    className="basic-single sm:text-sm"
                                    options={customerOption}
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div className="">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Status
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="select"
                                    value={orderStatus}
                                    onChange={(selectOption) => setOrderStatus(selectOption)}
                                    name="orderStatus"
                                    className=" sm:text-sm"
                                    options={orderStatusOption}
                                    styles={customStyles}
                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Date Picker
                            </label>
                            <div className="flex">
                                <input
                                    type="date"
                                    name="b_billing_date"
                                    value={values.b_billing_date}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}

                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />
                            </div>
                        </div>
                    </div>


                    {/* {billing address} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">

                        <div className="flex justify-between items-center">
                            <label className="text-black text-lg font-medium">Billing Address</label>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setBillingFlag((prev) => !prev)} /></div>
                        </div>
                        <div className={`${billingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                            <h4 className="text-sm text-[#0094FF] cursor-pointer" onClick={() => loadBillingHandle()}>Load Billing Address</h4>
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-center">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        First Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_firstName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name="b_firstName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="First Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_lastName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_lastName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Last Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Company Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_companyName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_companyName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Company Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 1
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_addressOne}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_addressOne"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 1"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 2
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_addressTwo}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_addressTwo"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 2"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_city}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_city"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Postcode / Zip
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_zipCode}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            name="b_zipCode"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="zipCode"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Country / Region
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"

                                            placeholder="Select"
                                            value={country}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            onChange={(selectOption) => {
                                                setCountry(selectOption)
                                                console.log("setCountry", selectOption)

                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={Country.getAllCountries()}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div>

                                        <Select
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            value={state}

                                            onChange={(selectOption) => {
                                                setState(selectOption)

                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={State?.getStatesOfCountry(
                                                (country)?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_email}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="email"
                                            name="b_email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Email"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Phone
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_phone}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            name="b_phone"
                                            min="0"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Phone"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={selectPaymentMethod}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => setSelectPaymentMethod(selectOption)}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={paymentMethod}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Transaction Id
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_transactionId}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            min="0"
                                            name="b_transactionId"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Transaction Id"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* {shipping address} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg text-black font-medium">Shipping Address</h2>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShippingFlag((prev) => !prev)} /></div>
                        </div>

                        <div className={`${shippingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                            <div className="flex gap-5">
                                <h4 className="text-sm text-[#0094FF] cursor-pointer" onClick={() => loadShippingHandle()}>Load Shipping Address</h4>
                                <h4 className="text-sm text-[#0094FF] cursor-pointer" onClick={() => copyBillingHandle()} >Copy Billing Address</h4>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-center">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        First Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name="firstName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="First Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            name="lastName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Last Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Company Name
                                    </label>
                                    <div className="">
                                        <input

                                            value={compnayName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            type="text"
                                            name="compnayName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Company Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 1
                                    </label>
                                    <div className="">
                                        <input
                                            value={addressOne}
                                            onChange={(e) => setAddressOne(e.target.value)}
                                            type="text"
                                            name="addressOne"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 1"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 2
                                    </label>
                                    <div className="">
                                        <input
                                            value={addressTwo}
                                            onChange={(e) => setAddressTwo(e.target.value)}
                                            type="text"
                                            name="addressTwo"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 2"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="">
                                        <input
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            type="text"
                                            name="city"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Postcode / Zip
                                    </label>
                                    <div className="">
                                        <input
                                            value={zipCode}
                                            onChange={(e) => setZipCode(e.target.value)}
                                            type="number"
                                            name="zipCode"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Country / Region
                                    </label>
                                    <div>

                                        <Select
                                            classNamePrefix="select"

                                            placeholder="Select"
                                            value={shipCountry}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={Country.getAllCountries()}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            onChange={(selectOption) => {
                                                setShipCountry(selectOption)
                                                console.log("setShipCountry", selectOption)

                                            }}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            value={shipState}
                                            name="userStatus"
                                            styles={customStyles}

                                            onChange={(selectOption) => {
                                                setShipState(selectOption)

                                            }}
                                            className="basic-single sm:text-sm"
                                            options={State?.getStatesOfCountry(
                                                (shipCountry)?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                        />

                                    </div>
                                </div>
                                {/* <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="">
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            name="email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Email"
                                        />

                                    </div>
                                </div> */}
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Phone
                                    </label>
                                    <div className="">
                                        <input
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="number"
                                            min="0"
                                            name="phone"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Phone"
                                        />

                                    </div>
                                </div>
                                {/* <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={selectShipPayMethod}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => setSelectShipPayMethod(selectOption)}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={paymentMethod}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div> */}
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Customer Provider Note
                                    </label>
                                    <div className="">
                                        <input
                                            value={customerProviderNote}
                                            onChange={(e) => setCustomerProviderNote(e.target.value)}
                                            type="text"

                                            name="customerProviderNote"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Customer Provider Note"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* {order details} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Order Details</label>
                        <div className="flex flex-col gap-5">
                            <label className="text-sm text-[#0094FF] cursor-pointer" onClick={() => setOpenWalletFlag(true)}>Use wallet balance</label>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 items-end">
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Add Product
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            isMulti
                                            placeholder="Select"
                                            value={selectProduct?.length > 0 ? selectProduct : []}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => {
                                                console.log("selectOption", selectOption)
                                                setSelectProduct(selectOption)
                                                setSummaryDataList(selectOption)
                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={productOption}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Apply Coupon
                                    </label>
                                    <div className="flex flex-col">
                                        <input
                                            value={values?.coupon}
                                            disabled={errorCouponMessage ? true : false}
                                            onChange={(event) => {
                                                handleChange(event)
                                                setCouponCode(event.target.value)
                                            }}
                                            // onBlur={handleBlur}

                                            type="text"
                                            name="coupon"
                                            className={`block w-full rounded-md  border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78] ${errorCouponMessage ? "bg-gray-200 opacity-70" : ""}`}
                                            placeholder="Coupon Code "
                                        />


                                    </div>
                                </div>
                                <div className="">
                                    <button type="button" className="rounded-lg px-4 py-2  text-sm font-semibold text-white bg-[#3a4b43]  "
                                        onClick={() => {
                                            if (couponRemoveFlag) {
                                                applyCouponVoucher()
                                            } else {
                                                removeCouponVoucher()

                                            }

                                        }}>
                                        {
                                            couponRemoveFlag ? "Apply" : "Remove"
                                        }
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>



                    {/* {summary} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Summary</label>
                        <div>
                            <SummaryTable columns={summary_columns()} data={summaryDataList} />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between  w-full mt-5 gap-5">
                            <div className="basis-2/5">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Coupon
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.coupon}
                                            readOnly
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name=""
                                            className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="coupon"
                                        />

                                    </div>
                                </div>
                                <div className="mt-5 flex gap-4 items-end">
                                    <div className="">
                                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                            Custom Amount
                                        </label>
                                        <div className="">
                                            <input
                                                value={customAmount}
                                                min="0"
                                                onChange={(e) => setCustomAmount(e.target.value)}
                                                // onBlur={handleBlur}

                                                type="number"
                                                name="customAmount"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                                placeholder="coupon"
                                            />

                                        </div>
                                    </div>
                                    <div className="">
                                        <button type="button" className="rounded-lg px-4 py-2  text-sm font-semibold text-white bg-[#3a4b43]  "
                                            onClick={() => {
                                                if (customAmountFlag) {
                                                    applyCustomAmount()
                                                } else {
                                                    removeCustomAmount()

                                                }

                                            }}>
                                            {
                                                customAmountFlag ? "Apply" : "Remove"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="basis-2/5 shadow-md border rounded-lg ">
                                <div className="flex flex-col  p-5 gap-x-5 gap-y-6 ">
                                    <div className="flex justify-between ">
                                        <h4 className="text-sm text-gray-500">Item SubTotal</h4>
                                        <span className="text-sm">

                                            {itemSubTotal}
                                        </span>
                                    </div>
                                    {
                                        shipCountry?.name ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Shipping</h4>
                                                <span className="text-sm">{shipCountry?.name === "India" ? "1000" : '2000'}</span>
                                            </div> :
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Shipping</h4>
                                                <span className="text-sm">{shipCountry?.name === "India" ? "1000" : '2000'}</span>
                                            </div>

                                    }
                                    {
                                        customPrice > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Custom Amount</h4>
                                                <span className="text-sm">{customPrice}</span>
                                            </div> : <></>
                                    }
                                    {
                                        couponAmount > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Coupon</h4>
                                                <span className="text-sm">-{couponAmount}</span>
                                            </div> : <></>
                                    }
                                    {
                                        parseInt(walletAmount) > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Wallet</h4>
                                                <span className="text-sm">-{parseInt(walletAmount)}</span>
                                            </div> : <></>
                                    }
                                    <div className="flex justify-between border-t-2 pt-4">
                                        <h4 className="text-md text-black">Order Total</h4>
                                        <span className="text-md">
                                            {totalAmount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* {shipment tracking} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <div className="flex justify-between items-center">
                            <h2 className="text-md font-medium text-lg">Shipment Tracking</h2>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShipmentTrackFlag((prev) => !prev)} /></div>
                        </div>
                        <div className={`${shipmentTrackFlag ? "grid grid-cols-1 md:grid-cols-3 gap-5 my-5" : "hidden "}`}>
                            <div>
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Tracking Number
                                </label>
                                <div className="">
                                    <input
                                        value={values.tracking_number}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        // onBlur={handleBlur}
                                        type="number"
                                        name="tracking_number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder="Tracking number"
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Shipping Provider
                                </label>
                                <div>
                                    <Select
                                        value={selectShipProvider}
                                        onChange={(selectOption) => setSelectShipProvider(selectOption)}
                                        className=" sm:text-sm"
                                        options={shippingProvider}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Date Shipped
                                </label>
                                <div className="flex">
                                    <input
                                        type="date"
                                        value={values.tracking_ship_date}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        name="tracking_ship_date"
                                        className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Marked order as
                                </label>
                                <div className="flex items-center gap-5">
                                    {
                                        markOrder.map((ele, index) => {
                                            return (
                                                <>
                                                    <div className="flex gap-2 items-center" key={index}>
                                                        <input type="radio"
                                                            checked={!!selectMarkOrder[ele.key]}
                                                            onChange={(e) => handleMarkOrder(ele.key, e.target.checked)}
                                                            className="rounded-full  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                        <span className="text-sm">{ele.label}</span>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* {order action} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Order Actions</label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-5">
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Choose Actions
                                </label>
                                <div>
                                    <Select
                                        classNamePrefix="select"
                                        defaultValue="select"
                                        placeholder="Select"
                                        value={selectOrderAction}
                                        // // onBlur={handleBlur}
                                        onChange={(selectOption) => setSelectOrderAction(selectOption)}

                                        className="basic-single sm:text-sm"
                                        options={orderAction}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 "></hr>

                    <div className="flex gap-5 justify-end ">
                        <button
                            onClick={clearForm}
                            type="button"
                            className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => handleSubmit()}
                            type="button"
                            className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>

            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }

            <UseWallet setOpenWalletFlag={setOpenWalletFlag} openWalletFlag={openWalletFlag} walletAmount={walletAmount} setWalletAmount={setWalletAmount} walletBalance={walletBalance} />
        </>
    )
}

export default AddOrder