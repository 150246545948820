import React, { useState, useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";
import { useFormik } from "formik";
import MasterServices from "../../../ApiServices/MasterServices";
import { userSchema } from "../../../schema";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toast";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

const AddUser = () => {
    const [selectRole, setSelectRole] = useState(null);
    const [editUser, setEdituser] = useState([])
    const navigate = useNavigate();
    const { user_id } = useParams();
    const [showPassWord, setShowPassWord] = useState(false)
    const [newShowPassWord, setNewShowPassWord] = useState(false)
    const roleTypeOption = [
        {
            label: "Admin",
            value: 3
        },
        {
            label: "User",
            value: 2
        }
    ]

    const formValues = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        roleType: "",
        newPassword: "",
    }

    const getSingleUser = async (id) => {
        try {
            const response = await MasterServices.getUserById(id);
            //console.log("response", response)
            if (response?.data?.data?.length > 0) {
                setEdituser(response?.data?.data)
            } else {
                setEdituser([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (editUser?.length > 0) {
            console.log("editUser", editUser)
            editUser.forEach((ele) => {
                setInitialValues({
                    firstName: ele?.u_user_first_name,
                    lastName: ele?.u_user_last_name,
                    email: ele?.u_user_email,
                    password: "",
                    roleType: ele?.u_user_role_id,

                })

                if (ele?.u_user_role_id) {
                    if (ele?.u_user_role_id === 1 || ele?.u_user_role_id === 2) {
                        setSelectRole({
                            label: "User",
                            value: ele?.u_user_role_id
                        })
                    }
                    else {
                        setSelectRole({
                            label: "Admin",
                            value: ele?.u_user_role_id
                        })
                    }

                }


            })

        }
    }, [editUser])

    const [initialValues, setInitialValues] = useState(formValues)
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
       // validationSchema: userSchema,
        onSubmit: async (values) => {

            const body = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password,
                role: selectRole?.value ? selectRole?.value : null,

            }

            if (editUser?.length > 0) {
                const payload = {
                    u_user_name: values.firstName,
                    u_user_last_name: values.lastName,
                    u_user_email: values.email,
                    //current_password: values.password,
                    new_password: user_id && values?.newPassword ? values?.newPassword : null,
                    u_user_role_id: selectRole?.value ? selectRole?.value : null,
                    u_user_id: user_id ? parseInt(user_id) : null
                }
                console.log("payload", payload)
                editUserHandle(payload)
            } else {
                addUserHandle(body)
            }
            console.log("body", body)
        }
    })

    const editUserHandle = async (payload) => {
        try {
            const response = await MasterServices.editUser(payload);
            console.log("response", response)
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                navigate("/user-page")
            } else {
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            toast.error(error?.data?.message)
        }
    }

    const addUserHandle = async (payload) => {
        try {
            const response = await MasterServices.addUsers(payload);
            console.log("response", response)
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                navigate("/user-page")
            } else {
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            toast.error(error?.data?.message)
        }

    }

    const clearForm = () => {
        navigate("/user-page")
    }

    useEffect(() => {
        const fetchData = async () => {
            if (user_id) {
                await getSingleUser(user_id)
            }
        }
        fetchData()
    }, [user_id])

    // console.log("selectRole",selectRole)
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{user_id ? "Edit User" : "Add User"}</h1>
            </div>
            <form className="py-5 flex flex-col  "
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit()
                }}
            >
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="border-b-[1px] border-[#ebac78] p-5">
                        <h1 className="text-md font-semibold text-black">User Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 rounded-b-lg  gap-5 bg-white  p-5 shadow-sm py-8">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={values.firstName}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="firstName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="First Name"
                                />
                                {errors.firstName && touched.firstName && (
                                    <div className="text-red-600 text-sm">{errors.firstName}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={values.lastName}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="lastName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Last Name"
                                />
                                {errors.lastName && touched.lastName && (
                                    <div className="text-red-600 text-sm">{errors.lastName}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={values.email}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="email"
                                    name="email"
                                    readOnly={!!user_id}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Email"
                                />
                                {errors.email && touched.email && (
                                    <div className="text-red-600 text-sm">{errors.email}</div>
                                )}

                            </div>
                        </div>
                        <div className={`${user_id ? "hidden" : ""}`}>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                {user_id ? "Current Password" : "Password"}
                            </label>
                            <div className="flex flex-col ">
                                <div className="relative">
                                    <input
                                        value={values.password}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        type={showPassWord ? "text" : "password"}
                                        name="password"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder="password"
                                    />
                                    <div className="absolute right-0 bottom-0 p-2">
                                        {
                                            showPassWord ?
                                                <EyeIcon className="w-4 h-4" onClick={() => setShowPassWord(false)} /> :
                                                <EyeSlashIcon className="w-4 h-4" onClick={() => setShowPassWord(true)} />
                                        }
                                    </div>
                                </div>
                                {errors.password && touched.password && (
                                    <div className="text-red-600 text-sm">{errors.password}</div>
                                )}

                            </div>
                        </div>
                        {
                            user_id &&
                            <div>
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    New Password
                                </label>
                                <div className="flex flex-col ">
                                    <div className="relative">
                                        <input
                                            value={values.newPassword}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type={newShowPassWord ? "text" : "password"}
                                            name="newPassword"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="New Password"
                                        />
                                        <div className="absolute right-0 bottom-0 p-2">
                                            {
                                                newShowPassWord ?
                                                    <EyeIcon className="w-4 h-4" onClick={() => setNewShowPassWord(false)} /> :
                                                    <EyeSlashIcon className="w-4 h-4" onClick={() => setNewShowPassWord(true)} />
                                            }
                                        </div>
                                    </div>

                                    {errors.newPassword && touched.newPassword && (
                                        <div className="text-red-600 text-sm">{errors.newPassword}</div>
                                    )}

                                </div>
                            </div>
                        }
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Role
                            </label>
                            <div className="flex flex-col">
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectRole}
                                    //value={values.roleType ? { value: values.roleType, label: roleTypeOption.find(option => option.value === values.roleType)?.label } : null}
                                    onChange={(selectedOption) => {
                                        setSelectRole(selectedOption)
                                        setFieldValue('roleType', selectedOption ? selectedOption.value : '');

                                    }}
                                    options={roleTypeOption}
                                    name="roleType"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />
                                {errors.roleType && touched.roleType && (
                                    <div className="text-red-600 text-sm">{errors.roleType}</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end mt-5 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddUser