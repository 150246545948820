import React from "react";
import { SortDownIcon, SortUpIcon, SortIcon } from "../../assests/icons/sorting";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useAsyncDebounce,
    usePagination,
} from 'react-table';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    // 
    const [value, setValue] = React.useState(globalFilter);
    const onFilterChange = useAsyncDebounce((value) => {
        console.log("onFilterChange", value)

        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <label className="flex gap-x-2 items-baseline ">
            <input
                type="text"
                className="rounded-md border-gray-300 shadow-sm focus:border-[#ebac78] focus:ring focus:ring-[#ebac78] focus:ring-opacity-10 "
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onFilterChange(e.target.value);
                }}
                placeholder={`${count} records`}
            />
        </label>
    );
}

const Table = ({ columns, data, table_id, printRef, zoom, pagination, goToThePage, loading, pageSizeState, setPageSizeState }) => {

    const { state,
        pageOptions,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        preGlobalFilteredRows,
        setGlobalFilter,
        setPageSize, } = useTable(
            {
                columns,
                data,
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination // new
        )
    return (
        <div className="">
            <div className="sm:flex sm:gap-x-2 mt-5 ">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="mt-2 sm:mt-0" key={column.id}>
                                {column.render('Filter')}
                            </div>
                        ) : null
                    )
                )}
            </div>

            {/* table ui */}
            <div id={table_id} ref={printRef} className={`mt-4 flex flex-col ${zoom ? zoom : ''}`}>
                <div className="-my-2 overflow-x-auto enable-scrollbar rounded-[10px]   sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
                            <table  {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50 whitespace-nowrap">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props
                                                <th
                                                    scope="col"
                                                    className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                    {...column.getHeaderProps({
                                                        style: { width: column.width },
                                                    })}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        {column.render('Header')}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <SortDownIcon className="w-4 h-4 text-gray-400" />
                                                                ) : (
                                                                    <SortUpIcon className="w-4 h-4 text-gray-400" />
                                                                )
                                                            ) : (
                                                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                                            )}
                                                        </span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white divide-y divide-gray-200"
                                >
                                    {page?.length > 0 ? (page.map((row, i) => {

                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className={`px-6 py-4 whitespace-nowrap text-left `}
                                                            role="cell"
                                                        >
                                                            {cell.column.Cell.name === 'defaultRenderer' ? (
                                                                <div className="text-sm text-gray-500">
                                                                    {cell.render('Cell')}
                                                                </div>
                                                            ) : (
                                                                cell.render('Cell')
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })) : (

                                        <>{headerGroups.map((headerGroup) => (
                                            <tr>
                                                <td className='p-5 text-center whitespace-nowrap w-full text-gray-500' colSpan={headerGroup.headers.length}>No Data Found</td>
                                            </tr>
                                        ))}</>


                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* pagination */}
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
                <div className="-mt-px flex w-0 flex-1">
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </button>
                    <div className='inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium'>
                        {preGlobalFilteredRows.length < 50 ? <span>
                            Data{' '}
                            <strong>
                                {preGlobalFilteredRows.length} of {preGlobalFilteredRows.length}
                            </strong>{' '}
                        </span> :
                            <span>
                                Data{' '}
                                <strong>
                                    {state.pageSize} of {preGlobalFilteredRows.length}
                                </strong>{' '}
                            </span>
                        }
                    </div>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    {/* Render pages dynamically */}
                    {pageOptions.map((page, key) => {
                        // Check if the page is within the visible range
                        const isVisible =
                            (page < 2) ||                                      // First two pages are always visible
                            (page > state.pageIndex - 2 && page < state.pageIndex + 3) || // Pages around current page
                            (page > pageOptions.length - 3);                   // Last two pages are always visible

                        // If page is visible, render the button
                        if (isVisible) {
                            return (
                                <button
                                    key={key}
                                    onClick={() => gotoPage(page)}
                                    className={`inline-flex items-center border-t-2 ${page === state.pageIndex ? 'border-cyan-500 text-cyan-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} px-4 pt-4 text-sm font-medium`}
                                >
                                    {page + 1}
                                </button>
                            );
                        } else if (page === 2 && state.pageIndex > 3) { // Render ellipsis before the third page if pageIndex is greater than 3
                            return (
                                <span key={key} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
                            );
                        } else if (page === pageOptions.length - 3 && state.pageIndex < pageOptions.length - 4) { // Render ellipsis after the third-to-last page if pageIndex is less than length - 4
                            return (
                                <span key={key} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
                            );
                        }

                        // If page is not visible and not an ellipsis, render null
                        return null;
                    })}
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <button
                        onClick={() => nextPage()} disabled={!canNextPage}
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                </div>
            </nav>
        </div>
    )
}

export default Table