
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import { parent_attribute_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";


const ParentAttribute = () => {
    const [loading, setLoading] = useState(true)
    const [attributeData,setAttributeData] = useState([]);
    const [filterAttributeData,setFilterAttributeData] = useState([]);
    const [selectStatus,setSelectStatus] = useState(null);
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })
    const parentData = [
        {
            name: "xus",
            status: "ndkjfn",
        },
        {
            name: "abbd",
            status: "ndkjfn",
        }
    ]

    useEffect(() => {
        const fetchData = ()=>{
            getParentAttributes()
        }
        fetchData()

    }, []);

    const statusOption = [
        {
            label:"Active",
            value:"Y"
        },
        {
            label:"Inactive",
            value:"N"
        },
    ]

    const getParentAttributes = async () => {
        try {
            const resp = await MasterServices.getAttributes();
            console.log("resp",resp)
            if(resp?.data?.data?.length>0){
                setAttributeData(resp?.data?.data);
                setFilterAttributeData(resp?.data?.data)
                setLoading(false)
            }

        } catch (error) {
            setAttributeData([]);
            setFilterAttributeData([])
            console.log(error)
        }
    }

    useEffect(()=>{
        if(dateInput?.startDate || dateInput.endDate || selectStatus){

            const filterData = filterAttributeData.filter((ele)=>{
                    const dateFilter = moment(ele?.p_product_attributes_attribute_created_at).format("YYYY-MM-DD");
                    const startDate = dateInput?.startDate ?  moment(dateInput?.startDate).format("YYYY-MM-DD"):null;
                    const endDate = dateInput?.endDate ?  moment(dateInput?.endDate).format("YYYY-MM-DD"):null;
                    const statusFlag = selectStatus?.value ? selectStatus?.value:null

                    const statusMatches = statusFlag ? ele?.p_product_attribute_active ===statusFlag :true
                    const dateMatches = (!startDate || startDate <=dateFilter) && (!endDate || endDate >=dateFilter);


                    return statusMatches && dateMatches
                    
            })

            if(filterData?.length>0){
                setAttributeData(filterData)
            }
            else{
                setAttributeData([])
            }
        }
    },[dateInput,selectStatus])

    const clearForm = ()=>{
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setSelectStatus(null)
        setAttributeData(filterAttributeData);

    }

    const downloadExcelBtn = ()=>{
        const filterData = attributeData?.map((ele)=>{
            return{
                Id:ele?.p_product_attribute_id,
                ParentName:ele?.p_product_attribute_name,
                Status:ele?.p_product_attribute_active,
                CreatedAt : moment(ele?.p_product_attributes_attribute_created_at).format("YYYY-MM-DD")
            }
        })

       downloadSheet(filterData,"parentAttribute")

       //console.log("filterData",filterData)
    }


    return (
        <>
            <div className=" flex items-end justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Parent Attribute</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the parent attribute.</p>
                </div>
                <Link

                    to="/add-parentAttribute"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD PARENT ATTRIBUTE
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Attribute
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatus}
                                onChange={(selectedOption)=>{
                                    setSelectStatus(selectedOption)
                                }}
                               options={statusOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    startDate:e.target.value
                                   }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    endDate:e.target.value
                                   }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={parent_attribute_columns()}
                        data={attributeData}
                    />
            }

        </>
    )
}

export default ParentAttribute