export  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};