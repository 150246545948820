import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { PencilSquareIcon, EyeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";


export const user_columns = () => [
    {
        Header: "id",
        accessor: "u_user_id",
    },
    {
        Header: "name",
        accessor: "user_name",
        Cell: (cell) => {
            const username = cell.row.original
            return <span>{`${username?.u_user_first_name} ${username?.u_user_last_name}`}</span>
        }
    },
    {
        Header: "email",
        accessor: "u_user_email",
    },

    {
        Header: "created at",
        accessor: "u_user_created_at",
        Cell: (cell) => {
            const createdAt = cell.row.original.u_user_created_at
            return <span>{moment(createdAt).format("YYYY-MM-DD")}</span>
        }
    },
    {
        Header: "status",
        accessor: "status",
        Cell: (cell) => {
            const userActive = cell.row.original.u_is_user_active
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="">
                            <span className={`${userActive === "Y" ? "bg-green-100 text-green-800  " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>
                                {userActive === "Y" ? "Active" : "Inactive"}
                            </span>
                        </Link>
                    </div>
                </>
            )
        }
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const userID = cell.row.original.u_user_id;
//console.log("userID", cell.row.original);
            const userActive = cell.row.original.u_is_user_active
            return (
                <>
                    <div className="flex gap-5 relative group ">
                        <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg relative"
                            to={`/edituser/${userID}`}
                        >
                            <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />

                        </Link>
                        <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                            edit user
                        </div>


                    </div>
                </>
            )
        }

    },
]
export const order_columns = () => [
    {
        Header: "Order id",
        accessor: "m_order_id",
    },

    {
        Header: "Email",
        accessor: "m_billing_email",
        Cell:(cell)=>{
            const m_billing_email = cell.row.original.m_billing_email
            const m_shipping_email = cell.row.original.m_shipping_email
            return(
                <>
                    <span>{m_billing_email===null?m_shipping_email:m_billing_email}</span>
                </>
            )
        }
    },
    {
        Header: "Order status",
        accessor: "m_order_status",
    },
    {
        Header: "Amount",
        accessor: "m_total_order_price",
        Cell:(cell)=>{
            const productPrice= cell?.row?.original?.m_total_order_price;
            const shippingPrice = parseInt(cell?.row?.original?.m_shipping_price);
            const couponAmount = parseInt(cell?.row?.original?.m_coupon_amount);
            const customizeAmount = parseInt(cell?.row?.original?.customization_amount);

            const totalAmount = couponAmount && couponAmount>0?(productPrice-couponAmount)+shippingPrice+customizeAmount: (productPrice+shippingPrice+customizeAmount)
            return(
                <>
                    <span>{totalAmount}</span>
                </>
            )
        }
    },

    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const order_id = cell.row.original.m_order_id;
        //console.log("order_id", cell.row.original)


            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">

                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editorder/${order_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit order
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },

]

export const summary_columns = () => [
    {
        Header: "productname",
        accessor: (c) => {
            //console.log("summary_columns",c)
            return <span>{c.p_product_name}</span>
        }
    },
    {
        Header: "p_product_sku",
        accessor: "p_product_sku",
    },
    {
        Header: "p_product_price",
        accessor: "p_product_price",
    },
    {
        Header: "p_stok_quantity",
        accessor: "p_stok_quantity",
    },
    {
        Header: "total",
        accessor: "total",
        Cell: (cell) => {
            const productPrice = cell?.row?.original?.p_product_price;
            const productQuantity = cell?.row?.original?.p_stok_quantity;
            return (
                <>
                    <span className="text-sm text-gray-500">{productQuantity === 0 || !productQuantity ? productPrice : (productPrice * productQuantity)}</span>
                </>
            )
        }
    },
]

export const product_columns = () => [
    {
        Header: "Name",
        accessor: "p_product_name",
    },
    {
        Header: "short description",
        accessor: "p_product_short_desc",
    },
    {
        Header: "sku",
        accessor: "p_product_sku",
    },
    {
        Header: "price",
        accessor: "p_product_price",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const product_id = cell.row.original.p_porduct_id;
            //console.log("product_id", product_id)
            // console.log("product_id",cell.row.original)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editproduct/${product_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit product
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <Link 
                            className="px-3 py-1 bg-blue-200/50  rounded-lg"
                            to={`/viewproduct/${product_id}`}
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />

                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    },
]

export const category_columns = ({ subCatDataHandler }) => [
    {
        Header: "category_id",
        accessor: "category_id",
    },
    {
        Header: "p_category_name",
        accessor: "p_category_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const category_id = cell.row.original.category_id
            //console.log("category_id", category_id);
            const subCatData = cell?.row?.original?.sub_category;

            //console.log("subCateg", cell.row.original)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editcategory/${category_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit category
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <button className="px-3 py-1 bg-blue-200/50  rounded-lg"
                                onClick={() => {
                                    subCatDataHandler(subCatData);
                                    // setIsOpen((prev)=>!prev)
                                }}
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </button>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const subCategory_columns = () => [
    {
        Header: "id",
        accessor: "id",
    },
    {
        Header: "cat_name",
        accessor: "cat_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subCat_id = cell.row.original.id;
            // console.log("brand_id", brand_id)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link
                                to={`/editSubCategory/${subCat_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg ">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit subcategory
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    },
]

export const brand_columns = ({ subBrandHandler }) => [
    {
        Header: "Id",
        accessor: "m_designers_id",
    },
    {
        Header: "name",
        accessor: "m_name",
        // Cell:(cell)=>{
        //     console.log("brand_columns",cell)
        // }
    },


    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const brand_id = cell.row.original.m_designers_id;
            const subBrandData = cell?.row?.original.subdesigners
            // console.log("subBrandData", subBrandData)

            return (
                <>
                    <div className="flex gap-5">

                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editBrand/${brand_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit brand
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <button className="px-3 py-1 bg-blue-200/50  rounded-lg"
                                onClick={() => {
                                    subBrandHandler(subBrandData);
                                    // setIsOpen((prev)=>!prev)
                                }}
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </button>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]
export const subBrand_columns = () => [
    {
        Header: "m_designers_id",
        accessor: "m_designers_id",
    },
    {
        Header: "m_name",
        accessor: "m_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subBrand_id = cell.row.original.m_sub_designers_id;
            //.log("subBrand_id", subBrand_id)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link
                                to={`/editSubBrand/${subBrand_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                editSubBrand
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const tag_columns = () => [
    {
        Header: "name",
        accessor: "name",
    },
    {
        Header: "description",
        accessor: "description",
    },
    {
        Header: "slug",
        accessor: "slug",
    },
    {
        Header: "count",
        accessor: "count",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex items-center gap-5">

                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit tag
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg"
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />

                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const page_meta_columns = () => [
    {
        Header: "Page",
        accessor: "name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="text-sm text-blue-500">
                            View
                        </Link>
                        <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                            <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                        </Link>
                    </div>
                </>
            )
        }
    },
]


export const parent_attribute_columns = () => [
    {
        Header: "name",
        accessor: "p_product_attribute_name",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            return (
                <span>{cell.row.original.p_product_attribute_name}</span>
            )

        }

    },
    {
        Header: "status",
        accessor: "p_product_attribute_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_product_attribute_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                            // to={`/editproduct/${product_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit parentAttribute
                            </div>
                        </div>


                        {/* <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg"

                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div> */}

                    </div>
                </>
            )
        }

    },


]

export const child_attribute_columns = () => [
    {
        Header: 'Parent Name',
        accessor: "p_product_attribute_name"
    },
    {
        Header: "name",
        accessor: "p_child_attribute_name",
        Cell: (cell) => {
            //console.log("child_attribute_columns", cell.row.original)
            return (
                <span className="text-sm">{cell.row.original.p_child_attribute_name}</span>
            )
        }

    },
    {
        Header: "status",
        accessor: "p_child_attribute_active",
        Cell: (cell) => {
            // console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_child_attribute_active
            return (
                <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "} text-xs font-medium me-2 px-2.5 py-1 rounded`}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"

                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit childAttribute
                            </div>
                        </div>

                        {/* <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg">
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div> */}

                    </div>
                </>
            )
        }

    },
]


export const abandoned_columns = () => [
    {
        Header: 'customer',
        accessor: "customer",
        Cell:(cell)=>{
            const name = cell.row.original;
            return(
                <>
                    <span>{`${name?.u_user_first_name} ${name?.u_user_last_name}`}</span>
                </>
            )
        }
    },
    {
        Header: 'email',
        accessor: "u_user_email",
        Cell:(cell)=>{
            const name = cell.row.original;
            return(
                <>
                    <span>{`${name?.u_user_email}`}</span>
                </>
            )
        }
    },
    // {
    //     Header: 'phone',
    //     accessor: "u_user_mobile"
    // },
    {
        Header: 'sku',
        accessor: "p_product_sku"
    },
    {
        Header: 'cartValue',
        accessor: "p_product_price"
    },
]

export const vocuher_columns = ()=>[
    {
        Header: 'Vocuher id',
        accessor: "m_voucher_id"
    },
    {
        Header: 'Vocuher code',
        accessor: "m_voucher_code"
    },
    {
        Header: 'discount value',
        accessor: "m_discounted_value"
    },
    {
        Header: 'applicabel day',
        accessor: "m_applicable_day"
    },
    {
        Header: 'is flat',
        accessor: "is_flat"
    },
    {
        Header: 'expiry date',
        accessor: "m_expiry_date",
        Cell:(cell)=>{
            const expiryDate = cell.row.original.m_expiry_date
            return <span>{expiryDate.slice(0, 19)}</span>
        }
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            
            const voucher_id = cell.row.original.m_voucher_id;
            
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link
                                
                                 to={`/editVoucher/${voucher_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                editVoucher
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]