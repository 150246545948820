import React, { useState } from "react";
import AuthServices from "../../../ApiServices/AuthServices";
import { toast, ToastContainer } from "react-toast";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import logo from "../../../assests/images/revivifylogo.png"
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassWord, setShowPassWord] = useState(false);
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email: e.target.email.value,
      password: e.target.password.value
    }
    try {

      const response = await AuthServices.LoginForm(body);
      console.log("response", response);

      if (response?.status === 200 && response?.data?.success === true) {
        toast.success(response?.data?.message);
        localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
        localStorage.setItem("token", response?.data?.data?.token);
        //window.location.href = "/";
        navigate("/")
      } else {

        const errorMessage = response?.data?.message || 'An error occurred. Please try again.';
        toast.error(errorMessage);
      }
    } catch (error) {

      console.error("Login error", error);
      toast.error('An unexpected error occurred. Please try again later.');
    }
  }
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="">
          <img className=" w-[200px]  mx-auto" src={logo} alt="Your Company" />
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col gap-2  text-center">
          <h2 className="mt-5  text-xl font-bold leading-9 tracking-tight text-[#3a4b43]">
            Sign In
          </h2>
          <p className="text-base text-gray-600">Enter your email address and password to access admin panel.</p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 focus:border-[#3a4b43] focus:outline-none focus:ring-0  sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-[#3a4b43] ">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2 relative">
                <input

                  name="password"
                  type={showPassWord ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm  border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:border-[#3a4b43] focus:outline-none focus:ring-0"
                />
                <div className="absolute inset-y-0 right-0  pr-3 flex items-center ">
                  {
                    showPassWord ?
                      <EyeIcon className="w-4 h-4" onClick={() => setShowPassWord(false)} /> :
                      <EyeSlashIcon className="w-4 h-4" onClick={() => setShowPassWord(true)} />
                  }
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#3a4b43] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:scale-105 transition-all duration-300 delay-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>


        </div>
      </div>
    </>
  )
}

export default Login