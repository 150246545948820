import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { brand_columns, subBrand_columns } from "../../../components/tables/tableheader";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import Spinner from "../../../components/Spinner";
import { Dialog, Transition } from '@headlessui/react';
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";


const Brand = () => {
    const pages = [{ title: "Brands", href: "/brand-page", module_id: 1 }];
    const [brandData, setBrandData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterBrandData,setFilterBrandData] = useState([]);
    const [selectStatus,setSelectStatus] = useState(null);
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })
    const [subBrandData, setSubBrandData] = useState({
        isOpen: false,
        data: []
    })

    const statusOption = [
        {
            label:"Active",
            value:"Y"
        },
        {
            label:"Inactive",
            value:"N"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            await getBrand()
        }

        fetchData()
    }, [])

    const getBrand = async () => {
        try {

            const response = await MasterServices.getAllBrands();
           // console.log("getBrand", response)
            if (response?.data?.data?.length > 0) {
                setBrandData(response?.data?.data)
                setFilterBrandData(response?.data?.data)
                setLoading(false)
            }
        } catch (error) {
            setBrandData([])
            setFilterBrandData([])
            setLoading(false)
            toast.error("error in getBrand")
        }
    }
    const subBrandHandler = (data) => {
        console.log("subCatDataHandler", data);

        setSubBrandData({
            isOpen: true,
            data: data
        })
    }

    useEffect(()=>{
        if(dateInput?.startDate || dateInput.endDate || selectStatus){

            const filterData = filterBrandData.filter((ele)=>{
                    const dateFilter = moment(ele?.m_designer_created_at).format("YYYY-MM-DD");
                    const startDate = dateInput?.startDate ?  moment(dateInput?.startDate).format("YYYY-MM-DD"):null;
                    const endDate = dateInput?.endDate ?  moment(dateInput?.endDate).format("YYYY-MM-DD"):null;
                    const statusFlag = selectStatus?.value ? selectStatus?.value:null

                    const statusMatches = statusFlag ? ele?.m_designer_active ===statusFlag :true
                    const dateMatches = (!startDate || startDate <=dateFilter) && (!endDate || endDate >=dateFilter);


                    return statusMatches && dateMatches
                    
            })

            if(filterData?.length>0){
                setBrandData(filterData)
            }
            else{
                setBrandData([])
            }
        }
    },[dateInput,selectStatus])

    const clearForm = ()=>{
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setSelectStatus(null)
        setBrandData(filterBrandData);

    }
    const downloadExcelBtn = ()=>{
        const filterData = brandData?.map((ele)=>{
            return{
                Id:ele?.m_designers_id,
                Designer:ele?.m_name,
                Status:ele?.m_designer_active,
                CreatedAt : moment(ele?.m_designer_created_at).format("YYYY-MM-DD")
            }
        })

       downloadSheet(filterData,"designer")

       //console.log("filterData",filterData)
    }


    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Brand List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the brands.</p>
                </div>
                <Link

                    to="/add-brand"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD BRAND
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Brand
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatus}
                                onChange={(selectedOption)=>{
                                    setSelectStatus(selectedOption)
                                }}
                                options={statusOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    startDate:e.target.value
                                   }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    endDate:e.target.value
                                   }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :

                    <Table
                        columns={brand_columns({ subBrandHandler })}
                        data={brandData}
                    />
            }

            <Transition appear show={subBrandData.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={() => setSubBrandData({
                    isOpen: false,
                    data: []
                })}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-[500px]">
                                    <Table
                                        columns={subBrand_columns()}
                                        data={subBrandData?.data}
                                    />
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Brand;