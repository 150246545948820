import React from "react";
import Chart from "react-apexcharts";
const LineChart = (props) => {
    let seriesData = []
    props?.lineData?.data?.map((e) => {
    if(Object.keys(e)[0] != undefined)
    {
      seriesData.push({
        name: Object.keys(e)[0],
        data: Object.values(e)[0]
      })
    }
    })
    const state = {
        options: {
          chart: {
            id: "basic-bar"
          },
          xaxis: {
            categories: props.lineData?.date
          }
        },
        series: seriesData
      };
    
    return (
        <>
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                height="400"
                className="w-full h-full"
                
            />
        </>
    )
}

export default LineChart