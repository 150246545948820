import React from "react";
import { ToastContainer, toast } from "react-toast";
import MasterServices from '../ApiServices/MasterServices';


const UploadFileComp = async (file, onSuccess,setProgressData) => {
    const CHUNK_SIZE = 1024 * 1024 * 0.5; // 0.5MB chunk size
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let completedChunks = 0;

    // Generate a unique filename
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 15; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        result += chars[randomIndex];
    }
    const finalName = result + "-" + file.name;

    for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const chunk = file.slice(start, end);
        
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('originalname', finalName);
        formData.append('totalChunks', totalChunks);
        formData.append('currentChunkIndex', i);

        try {
            const response = await MasterServices.getProductImage(formData);
            if (response?.status === 200) {
                // toast.success(response?.data?.message);
                onSuccess(response?.data?.originalname);
                completedChunks++
                // const progress = Math.round(((i + 1) / totalChunks) * 100);
                const progress = Math.round((completedChunks / totalChunks) * 100);
                if(progress === 100){
                    toast.success("Upload successfully")
                }
                setProgressData(progress);
            } else {
                toast.error('Failed to upload chunk');
                break; // Stop further processing if a chunk fails
            }
        } catch (error) {
            console.error('Error uploading chunk', error);
            toast.error('Error uploading chunk');
            break; // Stop further processing if an error occurs
        }
    }
};

export default UploadFileComp;