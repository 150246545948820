import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { order_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { ToastContainer, toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";

const Orders = () => {
    const pages = [{ title: "Orders", href: "/order-page", module_id: 1 }];
    const [orderData, setOrderData] = useState([])
    const [filterOrderData,setFilterOrderData] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectOrderStatus,setSelectOrderStatus] = useState(null);
    const [customerOption,setCustomerOption] = useState([]);
    const [selectCustomerName,setSelectCustomerName] =useState(null);
    const [skuOption,setSkuOption] = useState([]);
    const [selectSku,setSelectSku] = useState(null)
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })


    useEffect(() => {
        const fetchData = async () => {
            await getOrder();
            await getAllProduct()
        }

        fetchData()
    }, [])

    const OrderStatusOption = [
        {
            label:"Active",
            value:"Y"
        },
        {
            label:"Inactive",
            value:"N"
        },
    ]

    const getOrder = async () => {
        try {

            const response = await MasterServices.getAllOrder();
            
            if (response?.data?.data?.length > 0) {
                //console.log("getOrder", response?.data?.data)
                setOrderData(response?.data?.data);
                setFilterOrderData(response?.data?.data)
                setLoading(false);

                const customerNameFilter = response?.data?.data.map((ele)=>({
                    label:ele?.m_billing_name,
                    value:ele?.m_billing_name
                }))

                setCustomerOption(customerNameFilter)
            }
        } catch (error) {
            toast.error("error in getOrder")
        }
    }

    const getAllProduct = async()=>{
        try{
            const response = await MasterServices.getAllProduct();
          
            if (response?.data?.data?.length > 0) {
              const filterData = response?.data?.data?.map((ele)=>({
                label:ele?.p_product_sku,
                value:ele?.p_porduct_id
              }))

              setSkuOption(filterData)
            }   
        }catch(error){
            setSkuOption([])
            console.log("getAllProduct",error)
        }
    }

    useEffect(()=>{
        if(dateInput?.startDate || dateInput?.endDate || selectOrderStatus ||  selectCustomerName || selectSku){
            const filterData = filterOrderData?.filter((ele)=>{
                const dateFilter = moment(ele?.m_order_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                const statusFlag  = selectOrderStatus?.value;
                const customer = selectCustomerName?.value;
                const skuName = selectSku?.value

                const customerMatches = customer ? ele?.m_billing_name ===customer :true;
                const skuMatches = skuName ? parseInt(ele?.p_product_id)===skuName :true
                const orderStatusMatches  = statusFlag ? ele?.m_is_order_active===statusFlag :true
                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);

                return customerMatches && skuMatches && orderStatusMatches && dateMatches;
            })
           // console.log("filterData",filterData)
            if(filterData?.length>0){
                setOrderData(filterData)
            }else{
                setOrderData([])
            }
        }
    },[dateInput,selectOrderStatus,selectCustomerName,selectSku])

    const clearForm = ()=>{
        setDateInput({
            startDate: "",
            endDate: ""
        })

        setSelectCustomerName(null)
        setSelectOrderStatus(null)

        setOrderData(filterOrderData);
        setSelectSku(null)
    }

    const downloadExcelBtn = ()=>{
         const filterData = orderData?.map((ele)=>{
             return{
                 Id:ele?.m_order_id,
                 UserId :ele?.u_user_id,
                 BillingName:ele?.m_billing_name,
                 Email:ele?.m_billing_email,
                 Phone:ele?.m_billing_phone,
                 TotalPrice:ele?.m_total_order_price,
                 ShippingPrice:ele?.m_shipping_price,
                 CouponAmount:ele?.m_coupon_amount,
                 CouponCode:ele?.m_coupon_code,
                 PaymentMethod:ele?.payment_method,
                 Status:ele?.m_order_status,
                 CreatedAt : moment(ele?.m_order_created_at).format("YYYY-MM-DD")
             }
         })
 
        downloadSheet(filterData,"orders")
 
        //console.log("filterData",filterData)
     }

    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Orders List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Orders.</p>
                </div>
                <Link

                    to="/add-order"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD ORDERS
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Search Product
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectSku}
                                onChange={(selectedOption)=>{
                                    setSelectSku(selectedOption)
                                }}
                                options={skuOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectOrderStatus}
                                onChange={(selectedOption)=>{
                                    setSelectOrderStatus(selectedOption)
                                }}
                                options={OrderStatusOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Shipping Providers
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Customer
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectCustomerName}
                                onChange={(selectedOption)=>{
                                    setSelectCustomerName(selectedOption)
                                }}
                                options={customerOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    startDate:e.target.value
                                   }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                styles={customStyles}
                                value={dateInput.endDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    endDate:e.target.value
                                   }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                         onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={order_columns()}
                        data={orderData}
                    />
            }
        </>
    )
}

export default Orders;