import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { product_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";

const Product = () => {
    const pages = [{ title: "Product", href: "/product-page", module_id: 1 }];
    const [productData, setProductData] = useState([]);
    const [filterProductData, setFilterProductData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [skuOption, setSkuOption] = useState([]);
    const [selectSku, setSelectSku] = useState(null);
    const [selectStatus, setSelectStatus] = useState(null);
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    useEffect(() => {
        const fetchData = async () => {
            await getProducts()
        }

        fetchData()
    }, [])

    const statusOption = [
        {
            value: "Y",
            label: "Published"
        },
        {
            value: "N",
            label: "Draft"
        },
    ]

    const getProducts = async () => {
        try {

            const response = await MasterServices.getAllProduct();
            // console.log("getProducts", response)
            if (response?.data?.data?.length > 0) {
                // console.log("getProducts",response?.data?.data)
                setProductData(response?.data?.data);
                setFilterProductData(response?.data?.data)
                setLoading(false);
                const skuFilter = response?.data?.data?.map((ele) => ({
                    label: ele?.p_product_sku,
                    value: ele?.p_product_sku
                }))
                setSkuOption(skuFilter)
            }
        } catch (error) {
            toast.error("error in getProducts")
        }
    }

    useEffect(() => {
        //console.log("filterProductData",filterProductData)
        // console.log("selectStatus",{
        //     selectStatus
        // })
        if (dateInput?.startDate || dateInput?.endDate || selectSku || selectStatus) {

            const filterData = filterProductData.filter((ele) => {
                const dateFilter = moment(ele?.u_product_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                const statusFlag = selectStatus?.value;
                const skuName = selectSku?.value;

                const skuMatches = skuName ? ele?.p_product_sku === skuName : true;

                const statusMatches = statusFlag ? ele?.p_product_published === statusFlag : true;

                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);

                return skuMatches && statusMatches && dateMatches;
            })
           // console.log("filterData", filterData)
            if (filterData?.length > 0) {
                setProductData(filterData)
            } else {
                setProductData([])
            }
        }

    }, [selectSku, filterProductData, selectStatus, dateInput])


    const clearForm = () => {

        setSelectStatus(null)
        setDateInput({
            startDate: "",
            endDate: ""
        })
        setSelectSku(null)
        setProductData(filterProductData)
    }
    
    const downloadExcelBtn = ()=>{

        // console.log("userData",userData)
 
         const filterData = productData?.map((ele)=>{
             return{
                 Id:ele?.p_porduct_id,
                 ProductName :ele?.p_product_name,
                 ShortDescription:ele?.p_product_short_desc,
                 SKU :ele?.p_product_sku,
                 Price:ele?.p_product_price,
                 CreatedAt : moment(ele?.u_product_created_at).format("YYYY-MM-DD")
             }
         })
 
        downloadSheet(filterData,"product")
 
        console.log("filterData",filterData)
     }

    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Product List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the products.</p>
                </div>
                <Link

                    to="/add-product"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD PRODUCT
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Product
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectSku}
                                options={skuOption}
                                onChange={(selectedOption) => {
                                    setSelectSku(selectedOption)
                                }}
                                name="selectSku"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"

                                placeholder="Select"
                                value={selectStatus}

                                options={statusOption}
                                onChange={(selectedOption) => {
                                    setSelectStatus(selectedOption)
                                }}
                                name="selectStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>

            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={product_columns()}
                        data={productData}
                    />
            }
        </>
    )
}

export default Product