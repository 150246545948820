import * as Yup from "yup"


export const productSchema = Yup.object({
    p_product_name: Yup.string().required("Please Enter Field"),
    p_product_short_desc: Yup.string().required("Please Enter Field"),
    p_product_price: Yup.number().required("Please Enter Field"),
    p_original_purchase_price: Yup.number().required("Please Enter Field"),
})

export const userSchema = Yup.object({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    newPassword:Yup.string()
    .min(6, 'New Password must be at least 6 characters'),
    roleType: Yup.string()
        .nullable()
        .required('Role is required')

})